// main.js (Vue 2 setup)

// Core + your app
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

// 1) HighchartsVue
import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue)

// 2) Vue Form Generator
import VueFormGenerator from 'vue-form-generator'
import 'vue-form-generator/dist/vfg.css'
Vue.use(VueFormGenerator)

// 3) vfg-field-array (named export)
import { FieldArray } from 'vfg-field-array'
// Register as <field-array>
Vue.component('field-array', FieldArray)

// 4) vfg-field-object (named export)
import {FieldObject} from 'vfg-field-object'
// Register as <field-object>
Vue.component('field-object', FieldObject);

import FieldPhone from "@/components/FieldPhone.vue";
Vue.component("field-phone", FieldPhone);

// 5) VueTelInput (with globalOptions)
import VueTelInput from 'vue-tel-input'
const globalOptions = {
  mode: 'auto',
  // Add any other default config you want here
}
Vue.use(VueTelInput, globalOptions)


import FieldHtml from "@/components/FieldHtml.vue";
Vue.component("field-html", FieldHtml);

// Optional: Suppress the production tip
Vue.config.productionTip = false

// 6) Global Filter
Vue.filter('formatEuro', function(value) {
  if (!value) return ''
  const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR'
  })
  return formatter.format(value)
})

// 7) Global Directive
Vue.directive('focus-on-visible', {
  inserted(el) {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        el.focus()
      }
    }, { threshold: 1.0 })

    el._observer = observer
    observer.observe(el)
  },
  unbind(el) {
    if (el._observer) {
      el._observer.disconnect()
    }
  }
});




// 8) Create + Mount Vue App
new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount('#app')