<template>
  <div :key="product_id ? product_id : product._id"  :class="'product ' + expanded" v-if="product">
        
        <div class="flex product__conn">
            <div class="flex-5 flex-20-md flex-10-sm product__controles order-md-n9">
                <SwitchInput :id="product._id" :checked="product.fullfilled_bysf" :change="toggleFulfilment" :value="product._id" :name="product._id + '__check'" /> 
                <div class="vertical_dots" v-if="product.parent === null" v-on:click="expanded = !expanded">{{ !expanded ? '...' : '' }}</div>
            </div>

            <div v-if="!hide_req && product.parent === null" class="flex-5 flex-20-md text-center  product_link_box product__conn__links order-md-10 hidden-sm">
                <button :title="trx.products.button_link_name" :class="link_expanded ? 'active' : ''" v-on:click="link_expanded = !link_expanded ">
                    <span>🔗</span>
                    <span title="Aantal gelinkte producten" class="link_badge" v-if="product.parent === null">{{ product.childeren ? product.childeren.length : 0}}</span>
                </button>
            </div>

            <div v-if="!hide_req" class="flex-10 flex-20-md text-center product__conn hidden-sm check-box-controles order-md-10">
                <div class="flex">
                    <input class="flex-20" v-model="product.share_track_trace" v-on:change="setShareTT({product_id: product._id, track_trace_email: product.share_track_trace })" type="checkbox" :id="'share_track_trace'+product._id">
                    <label class="flex-80" :for="'share_track_trace'+product._id">Share T & T</label>
                </div>
                <div class="flex">
                    <input class="flex-20" v-model="product.sync_stock" v-on:change="setStockSync({product_id: product._id, sync_stock: product.sync_stock })" type="checkbox" :id="'share_track_trace'+product._id">
                    <label class="flex-80" :for="'share_track_trace'+product._id">Sync Stock</label>
                </div>    
                <div class="flex">
                    <input class="flex-20" v-model="product.fake_stock" v-on:change="setFakeStock({product_id: product._id, fake_stock: product.fake_stock })" type="checkbox" :id="'share_track_trace'+product._id">
                    <label class="flex-80" :for="'share_track_trace'+product._id">Fake Stock</label>
                </div>
            </div>
           
            <div :class="'flex-20-md flex-30-sm product__image order-md-n9 '+ (!hide_req ? 'flex-5 ':'flex-10 ')  " v-if="(product.parent === null) && (product.img_url || product.images)">
                <object v-if="product.images && product.images[0]" height="auto" width="50px" :data="'data:image/jpeg;base64,'+product.images[0].data.img_data" />
                <img v-else height="auto" width="40px" :src="product.img_url" style="padding:1rem" />
            </div>

            <div  v-if="!hide_req" class="flex-10 flex-40-md flex-grow-1 flex-100-sm product__name order-md-10">    
                <div class="">
                    {{ product.offer_id }}
                    <h4 class="product__name--title" >{{product.name}}</h4>
                    <span class="platform">{{product.platform}}</span> 
                    <span class="api_name">{{product.api_name}}</span>
                    
                </div>
                <hr>
                <div class="product__barcode">
                    <p titl="ean" class="product__barcode--ean"> {{product.ean}} 
                    <span v-if="product.parent === null" v-on:click="cr_checkin_code({ean:product.ean, amount: 0, type: 'return', location: product.locations[0] ? product.locations[0].location_id.parent.name+'-'+product.locations[0].location_id.name : '' })">
                        🖨️
                    </span>
                    </p>
                    <span title="bsku" class="product__barcode--bsku"> {{product.bsku}} </span>
                </div>
            </div>

            <div class="flex-5 flex-20-md flex-10-sm product__stock order-md-n3" v-if="product.parent === null" >
               <div>
                {{trx.products.total_stock}}<br/>
                <p><b>{{ product.sf_stock }}</b></p>
               </div>
               
            </div>
            
            <div class="flex-5 flex-20-md flex-10-sm  product__stock order-md-n3" v-if="product.parent === null" >
               <div>
                {{trx.products.total_sold_30d}}<br/>
                <p>{{ product.total_sold_30d }}</p>
                <hr>
                Replenish in<br/>
                <p>{{ product.days_until_out_of_stock }}</p>
                days
               </div>
            </div>
            
            <div class="flex-20 flex-40-md flex-100-sm flex shipment_methods flex-grow-1 order-md-10 hidden-sm" v-if="product.parent === null">
               <div style="margin-right: -3rem;margin-left: 4rem;">    
                <SelectShipMethod        :product="product" />
                <SelectShipBox        :product="product" />
                <SelectProductSurchage  :product="product" />
               </div>
            </div>
            
            <div v-if=" (product.parent === null) && (disableLocations !== true) && authCheck('/locations/assign', 'POST', user.curr_role)" class="flex-25 flex-60-md flex-100-sm order-md-n1 flex-grow-1 locations_order">
                <ProductLocation v-if="product.parent === null" :node="product" :i="i" :inboundItemId="inboundItemId"  @location-updated="handleLocationUpdate" />
            </div>  

            
            <!-- <div v-if="product.lvb_stock" class="flex-5 flex-100-md product__stock" >
               <div>
                {{trx.products.lvb_stock}}<br/>
                <h3>{{ product.lvb_stock }}</h3>
                <span>{{product.lvb_grd_stock}}</span>
               </div>
            </div> -->
        </div>

        <ProductLink class="flex-80" v-bind:key="'prl_'+product._id" :product="product" :link_expanded="link_expanded" />

        <div v-if="expanded" class="product__expand_conn flex">
            
            <div class="controles_menu flex-20 flex-100-md"> 
                <div class="vertical_dots" v-on:click="expanded = !expanded">{{ !expanded ? '...' : 'X' }}</div>
                <br />
                <div class="">
                    <button :disabled="product_options" :class="product_options ? 'active' : ''" v-on:click="toggleExpanded('product_options')"  title="Product options"> <span>🔍</span> {{ trx.products.button_options_name }}</button>
                </div>
                <div v-if="product.parent === null" class="product_bundel_box">
                    <button :disabled="aut_expanded" :class="aut_expanded ? 'active' : ''" v-on:click="get_automations(product._id)" title="Automatiseringen"><span>🧬</span> {{ trx.automations.button_name }}</button>
                    <span title="Aantal automatiseringen" class="link_badge" v-if="product.automations && product.automations.length >0">{{product.automations.length}}</span>
                </div>
                <div v-if="authCheck('/product/history_locations/*', 'GET', user.curr_role)" class="product_history_locations">
                    <button :disabled="hist_l_expanded" :class="hist_l_expanded ? 'active' : ''" v-on:click="ini_history_locaations(product._id)"  title="history_locations"><span>🔍</span>  {{ trx.history_locations.button_name }}</button>
                </div>
                
                <div v-if="product.parent === null" class="">
                    <button :disabled="sta_expanded" :class="sta_expanded ? 'active' : ''" v-on:click="toggleExpanded('sta_expanded')"  title="Stock Analytics">  <span>🔍</span> {{ trx.stock_analysis.button_name }}</button>
                </div>

                <div class="product_customs_settings">
                    <button :disabled="customs_expanded" :class="customs_expanded ? 'active' : ''" v-on:click="toggleExpanded('customs_expanded')"  title="Customs">  <span>🛃</span> {{ trx.products.customs.button_name }}</button>
                </div>
                <div v-if="user && user.is_admin" class="product_customs_settings">
                    <button :disabled="recount_expanded" :class="recount_expanded ? 'active' : ''" v-on:click="toggleExpanded('recount_expanded')"  title="Recount">  <span>🧮</span> {{ trx.products.recount.button_name }}</button>
                </div>
                <div v-if="authCheck('/product/transfer/*', 'POST')" class="product_bundel_box">
                    <button :disabled="transfers_expanded" :class="transfers_expanded ? 'active' : ''" v-on:click="toggleExpanded('transfers_expanded')"  title="Recount">  <span>🔁</span>Open Transfers</button>
                    <span title="Aantal automatiseringen" class="link_badge" v-if="transfer_locations && transfer_locations.length > 0">{{transfer_locations.length}}</span>
                </div>
            </div>
            <div class="opt_conn flex-80 flex-100-md">
                <!-- <div v-if="product_options" class=" flex-80 product__options"> 
                    <div class="product__note">
                        <h4 class="product__note--title">{{trx.products["Note"]}}</h4>
                        <textarea v-model="product.note" v-on:change="setNote({product_id: product._id, data: product.note})"></textarea>
                    </div> 
                    <div class="product__note">
                        <h4 class="product__note--title">{{trx.products.pick_note}}</h4>
                        <textarea v-model="product.pick_note" v-on:change="setPickNote({product_id: product._id, data: product.pick_note})"></textarea>
                    </div> 
                    <div class="product__note">
                        <h4 class="product__note--title">{{trx.products.pack_note}}</h4>
                        <textarea v-model="product.pack_note" v-on:change="setPackNote({product_id: product._id, data: product.pack_note})"></textarea>
                    </div> 
                    <hr>  
                    <div class="product__return">
                        <h4 class="product__return--title">{{trx.products["Return instruction"]}}</h4>
                        <textarea v-model="product.return_instruction" v-on:change="setReturnInstruction({product_id: product._id, data: product.return_instruction})"></textarea>
                    </div>

                    <button class="color_dark_red" v-on:click="delete_product({ _id: product._id })">Delete product</button>
                </div> -->
                
                <ProductInformation v-if="product_options" class="productInformationComponent" :product="product"/>

                <div style="display:none" class=" NOT_USED">
                
                    <hr>
                    <br />
                    
                    <!-- <div class="flex-50">
                        <div class="">{{trx.orders["Own stock"]}}</div>
                        <div class=""><p>{{product.own_stock}}</p></div>
                        <div class="">{{trx.products.sf_stock}}</div>
                        <div class=""><p>{{product.sf_stock}}</p></div>
                    </div>
                    
                    <div class="flex flex-15">
                        <div class="flex-50">
                            <div class="">{{trx.orders["Own stock"]}}</div>
                            <div class=""><p>{{product.own_stock}}</p></div>
                            <div class="">{{trx.products.sf_stock}}</div>
                            <div class=""><p>{{product.sf_stock}}</p></div>
                        </div>

                        <div class="flex-50 product__location__minmax">
                            <div class="flex-50 min">
                                <span>Min</span>
                                <input v-model="product.sf_stock_min" v-on:change="update_min_stock({ product_id: product._id, value: product.sf_stock_min })" />
                            </div>
                            <div class="flex-50 max">
                                <span>Max</span>
                                <input v-model="product.sf_stock_max" v-on:change="update_max_stock({ product_id: product._id, value: product.sf_stock_max })" />
                            </div> 
                        </div>
                    </div> -->
                </div>
                
                <StockAnalysis class="flex-80" v-if="sta_expanded" v-bind:key="'sta_'+product._id" :product_id="product._id" :ean="product.ean" :org_id="product.org[0]._id ? product.org[0]._id : product.org[0]" />
                
                <ProductAutomations class="flex-80" v-bind:key="'pra_'+product._id" :product="product" :aut_expanded="aut_expanded" />
                <HistoryLocations v-if="authCheck('/product/history_locations/*', 'GET', user.curr_role)" class="flex-80" v-bind:key="'hisl_'+product._id" :product="product" :hist_l_expanded="hist_l_expanded" />
                <CustomsSettings class="flex-80" v-bind:key="'customs_'+product._id" :product="product" :expanded="customs_expanded" />
                <RecountSettings class="flex-80" v-bind:key="'recount_'+product._id" :product="product" :expanded="recount_expanded" />
                <TransferLocations class="flex-80" v-bind:key="'transfers_'+product._id" :expanded="transfers_expanded" :locations="transfer_locations" />
            </div>


            
        </div>    

  </div>
</template>
        

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import vSelect from 'vue-select';
import SwitchInput from '@/components/Controls/Switch';
import ProductLocation from './product_location.vue';
import SelectShipBox from './SelectShipBox.vue'
import SelectShipMethod from './SelectShipMethod.vue';
import SelectProductSurchage from './SelectProductSurcharge.vue';
import trx from '@/translations';
import ProductLink from './product_link.vue';
import ProductAutomations from './product_automations.vue';
import HistoryLocations from './product_history_locations.vue';
import StockAnalysis from './product_stock_analysis.vue'
import CustomsSettings from './components/customs_settings.vue';
import RecountSettings from './components/recount_settings.vue';
import TransferLocations from './components/transfer_locations.vue';
import ProductInformation from './ProductItemProductInformation.vue'
import authCheck from "@/lib/authCheck";

/* eslint-disable no-debugger */
import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect)

export default {
    name: "ProductItem",
    computed: {
        ...mapGetters(['products', 'user', 'surcharges_list', 'current_org',]),
    },
    methods: {
        ...mapActions([
            'getProducts', 
            'get_product',
            'setEmail', 
            'delete_product',
            'getProductLocations', 
            'setShareTT', 
            'setStockSync',
            'setFakeStock',
            'syncProductsWoo', 
            'setShippingMethod', 
            'setProductLocation', 
            'setNote',
            'setPickNote',
            'setPackNote',
            'setReturnInstruction',  
            'update_min_stock', 
            'syncProducts', 
            'toggleFulfilment', 
            'setShipBox', 
            'link_product',
            'fetch_automations',
            'cr_checkin_code',
            'get_history_locations',
            'fetch_surcharges_list',
        ]),
        authCheck,
        async loadProduct() {
            
            try {
                const response = await this.$store.dispatch('get_product', this.product_id);

                if (response) {
                    this.product = response;  // locally set the product
                } else {
                    console.warn("No data available or data is not in the expected format.");
                }
            } catch (error) {
                console.error("Error fetching product:", error);
            }
        },
        handleLocationUpdate(newLocation) {
            console.log('Location updated:', newLocation);
            this.loadProduct();
        },
        ini_link_product: function(product_2) {
            this.link_product({product_1: this.product._id, product_2: product_2})
        },
        get_automations: function(prodcut_id) {
            this.fetch_automations(prodcut_id);
            this.toggleExpanded('aut_expanded')
        },
        ini_history_locaations: function(prodcut_id) {
            this.get_history_locations({product_id: prodcut_id});
            this.toggleExpanded('hist_l_expanded')
        },
        toggleExpanded(state) {
            // set all expanded state to false
            this.product_options = false
            this.aut_expanded = false
            this.link_expanded = false
            this.hist_l_expanded = false
            this.sta_expanded = false
            this.customs_expanded = false
            this.recount_expanded = false
            this.transfers_expanded = false
            // set the state that was passed to true
            this[state] = true
        }
    },
    components: {
        SwitchInput,
        ProductLocation,
        SelectShipBox,
        SelectShipMethod,
        SelectProductSurchage,
        ProductLink,
        ProductAutomations,
        HistoryLocations,
        StockAnalysis,
        CustomsSettings,
        RecountSettings,
        TransferLocations,
        ProductInformation
    },
    provide() {
        return {
            reloadProduct: this.loadProduct, // Provide the method
        };
    },
    async mounted() {
        if (!this.product) {
            await this.loadProduct();  // fetch the product when the component mounts
        }
       
    },
    async created() {
        if (!this.surcharges_list) {
            this.fetch_surcharges_list()
        }
        if (!this.product) {
            debugger;
            await this.loadProduct();  // fetch the product when the component mounts
        }
        // this.transfer_locations = this.product.locations.filter((x) => x.toTransfer && x.toTransfer === true && x.active === true);
        // this.get_product_image({ product_id: this.product._id, index: this.i })
    },
    props: {
        product_id:  {
            type: String,
            required: true
        },
        product: {
            type: Object
        },
        i: Number,
        component_name: String,
        vi_locations: Boolean,
        vi_shipment: Boolean,
        vi_surcharge: Boolean,
        vi_notes: Boolean,
        vi_options: Boolean,
        hide_req: Boolean,
        table_layout: Boolean,
        disableLocations: Boolean,
        inboundItemId: String,
    },
    data() {
        return {
            
            trx,
            expanded: false,
            aut_expanded: false,
            link_expanded: false,
            hist_l_expanded: false,
            sta_expanded: false,
            customs_expanded: false,
            recount_expanded: false,
            transfers_expanded: false,
            product_control_menu: false,
            product_options: true,
            transfer_locations: []
        }
    },
    
}    
</script>

<style lang="scss" scoped>
    h3 {
        margin: 0rem 0rem;
    }
    .controle__btns {
        margin: 2rem 0rem;
        margin-top:2rem;
    }
    .product__barcode {
        text-align: left;
        display: flex;
        align-content: center;
        flex-direction: column;
        justify-content: center;
    }
    .product__name {
        flex-grow: 1;
    }
    .vertical_dots {
        font-size: 4rem;
        line-height: 2rem;
        text-align: center;
        cursor: pointer;
    }
    .product_bundel_box {
        position: relative;
        .link_badge {
            padding: 0.2rem 0.7rem;
            background: var(--color-purple);
            color: var(--color-white);
            border-radius: 50%;
            position: absolute;
            left: 0.5rem;
            top:0.5rem
        }
    }

    .shipment_methods {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        max-width: 280px;
        min-width: 200px;
    }

    .check-box-controles {
        >div {
            padding-left: 1rem;
        }
        label  {
            margin-left: -1.2rem;
            text-align: left;
        }
    }

    @media screen and (max-width: $bp-medium) {
        .locations_order  {
            order: 1
        }
    }
    
    .productInformationComponent {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        height: 65rem;
        overflow-y: scroll;
    }
    

    // .product__location__minmax {
    //     position: relative;
    //     input {
    //         max-width: 80px;
    //         height: 50px;
    //         padding:20px 10px;
    //         padding-top: 30px;
    //     }
    //     .min span {
    //         top: 8px;
    //         left: 10px;
    //         position: absolute;
    //     }
    //     .max span {
    //         top: 65px;
    //         left: 10px;
    //         position: absolute;
    //     }
    // }

    
</style>