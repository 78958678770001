<template>
    <div>
      
      <div class="container mt5">
        <h2 class="subheader">{{this.trx.orders.order_type}} </h2>
        <br>
        <div class="buttons flex">
            <div class="flex-50">
                    <button @click="updateStep(1, { order_type: 'manual_order' })" class="checkin_button">
                        <span>
                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="5" y="5" width="40" height="40" rx="5" stroke="black" stroke-width="2"/>
                                <line x1="12" y1="15" x2="38" y2="15" stroke="black" stroke-width="2"/>
                                <line x1="12" y1="25" x2="38" y2="25" stroke="black" stroke-width="2"/>
                                <line x1="12" y1="35" x2="25" y2="35" stroke="black" stroke-width="2"/>
                            </svg>
                        </span>
                        <div>
                            {{this.trx.orders.manual_order.title}}
                            <p>{{ this.trx.orders.manual_order.description }}</p>
                        </div>
                    
                    </button>
                    <button @click="updateStep(1, {order_type: 'b2b_order'})" class="checkin_button">
                        <span>
                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="10" y="10" width="30" height="20" stroke="black" stroke-width="2"/>
                                <circle cx="15" cy="35" r="5" stroke="black" stroke-width="2"/>
                                <circle cx="35" cy="35" r="5" stroke="black" stroke-width="2"/>
                                <line x1="20" y1="35" x2="30" y2="35" stroke="black" stroke-width="2"/>
                            </svg>
                        </span>
                        <div>
                            {{this.trx.orders.b2b_order.title}}
                            <p>{{ this.trx.orders.b2b_order.description }}</p>
                        </div>
                        
                    </button>
            </div>
            <div class="flex-50">
                <button @click="updateStep(1, { order_type: 'amazon_fba_order' } )" class="checkin_button">
                <span>
                    <img width="50px" height="50px" src="https://cdn.brandfetch.io/amazon.com/w/512/h/512/symbol?c=1idgMT1DczYXT26Zs0h">
                </span>
                <div>
                    {{this.trx.orders.amazon_fba_order.title}}
                    <p>{{ this.trx.orders.amazon_fba_order.description }}</p>
                </div>
            </button>
            <button @click="updateStep(1, { order_type: 'bol_lvb_order' })" class="checkin_button">
                <img width="50px" height="50px" src="https://cdn.brandfetch.io/bol.com/w/50/h/50?c=1idgMT1DczYXT26Zs0h">
                <div>
                    {{this.trx.orders.bol_lvb_order.title}}
                    <p>{{ this.trx.orders.bol_lvb_order.description }}</p>
                </div>
                
            </button>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import trx from "@/translations";
  
  export default {
    name: "PreOrderCreate",
    props: {
        updateStep: Function,
    },
    data() {
      return {
        trx,
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .header {
    margin-top: 1rem;
    margin-bottom: 3.5rem;
  }
  
  .container {
    margin-bottom: 3rem;
    margin: 0 auto;
  }
  
  .navbar {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 6rem;
    list-style: none;
  }
  
  .active-link {
    background-color: var(--color-primary) !important;
  }
  .link {
    background-color: var(--color-primary);
    border-radius: 100px;
    color: var(--color-white);
    padding: 1rem 2rem 1rem 2rem;
    font-size: 2rem;
  }
  
  .subheader {
    margin-bottom: 3rem;
  }
  
  .buttons {
    gap: 2rem;
    button {
        p {
            line-height: 2rem;
        }
    }
  }
  
  .checkin_button {
    width: 100%;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    border: 2px solid var(--color-primary);
    padding: 2rem;
    border-radius: 1rem;
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--color-black);
    transition: all;
    transition-duration: 300ms;
    display: flex;
    align-items: center;
    align-content: center;
    background: var(--color-white);
    text-align: left;
    line-height: 2.6rem;
    min-height: 15rem;
    span, div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    img, svg {
        margin-right: 2rem;
        width: 50px;
        height: 50px;
    }
  }
  
  .checkin_button:hover {
    background-color: var(--color-primary);
    color: var(--color-white);
    p {
        color: var(--color-white);
    }
  }
  </style>
  