<template>
	<div class="form-container">
		<form @submit.prevent="submitForm" novalidate>
	
			<div class="flex">
				<div class="flex-50">
					<!-- Address Information -->
					<fieldset>
						<legend>{{ trx.orders.address_information }}</legend>

						<label for="country">{{ trx.orders.country }} *</label>
						<select id="country" v-model="shipment_details.countryCode" name="country" required>
							<option v-for="value in countries" :key="value.value" :value="value.value">{{ value.name }}</option>
						</select>

						<div class="flex">
							<div class="flex-50">
								<label for="postalcode">{{ trx.orders.postalcode }} *</label>
								<input 
									id="postalcode" 
									type="text" 
									placeholder="1234 AB" 
									v-model="shipment_details.postalcode" 
									name="postalcode" 
									required 
									pattern="^\d{4}\s?[A-Za-z]{2}$" 
								/>
							</div>
							<div class="flex-50">
								
								<label for="housenumber">{{ trx.orders.housenumber }} *</label>
								<input 
									id="housenumber" 
									type="number" 
									placeholder="42" 
									v-model="shipment_details.housenumber" 
									name="housenumber" 
									required 
									min="1"
									v-on:change="checkAddress"
								/>
								
							</div>
							
						</div>

						<div>
								<label for="addition">{{ trx.orders.addition }}</label>
								<input id="addition" type="text" placeholder="A" v-model="shipment_details.houseNumberExtension" name="housenumberextension" />
							</div>

						<label for="street">{{ trx.orders.streetname }} *</label>
						<input id="street" type="text" placeholder="Hoofdstraat" v-model="shipment_details.streetname" name="street" required />

						<div class="flex-50">
							<label for="place">{{ trx.orders.place }} *</label>
							<input id="place" type="text" placeholder="Amsterdam" v-model="shipment_details.city" name="city" required />
						</div>
						
					</fieldset>
				</div>
				<div class="flex-50">
					<!-- Personal Information -->
					<fieldset class="">
						<legend>{{ trx.orders.personal_information }}</legend>

						<label for="company">{{ trx.orders.company }}</label>
						<input id="company" type="text" placeholder="Voorbeeldbedrijf BV" v-model="shipment_details.company" name="company" />

						<div class="flex">
							<div class="flex-50">
								<label for="fname">{{ trx.orders.name }} *</label>
								<input id="fname" type="text" placeholder="Jan" v-model="shipment_details.name" name="name" required />
							</div>
							<div class="flex-50">
								<label for="surename">{{ trx.orders.last_name }} *</label>
								<input id="surename" type="text" placeholder="Jansen" v-model="shipment_details.surename" name="surename" required />
							</div>
						</div>
					</fieldset>

					<!-- Contact Information -->
					<fieldset class="">
						<legend>{{ trx.orders.contact_information }}</legend>

						<label for="email">{{ trx.Email }} *</label>
						<input 
							id="email" 
							type="email" 
							placeholder="voorbeeld@email.com" 
							v-model="shipment_details.email" 
							name="email" 
							required 
						/>

						<label for="phone">{{ trx.orders.phone }}</label>
						<input 
							id="phone" 
							type="tel" 
							placeholder="+31612345678" 
							v-model="shipment_details.phone" 
							name="phonenumber" 
							required 
							pattern="^\+?[0-9\s\-\(\)]+$" 
						/>
					</fieldset>
				</div>	
			</div>
			
		</form>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import trx from "@/translations";

export default {
  name: "OrderForm",
	props: {
		updateStep: Function,
	},
	data() {
		return {
			trx,
		}
	},
	computed: {
		...mapGetters(['possible_addresses', 'shipment_methods_list', 'shipment_details', 'countries']),
	},
	methods: {
		...mapActions(['searchAddress', 'validateAddress', 'setAddress', 'address', 'error', 'fetch_shipment_methods_list', 'fetch_countries']),
		checkAddress() {
			this.validateAddress({
				country: this.shipment_details.countryCode,
				postalCode: this.shipment_details.postalcode,
				houseNumber: this.shipment_details.housenumber
			});
		}
	},
	created() {
		this.fetch_shipment_methods_list();
		this.shipment_details.countryCode = this.shipment_details.countryCode ? this.shipment_details.countryCode : 'NL';
		if (this.countries.length === 0) {
			this.fetch_countries();
		}
	},
};
</script>
<style lang="scss" scoped>
.form-container {
	// max-width: 600px;
	margin: auto;
	background: #fff;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.flex {
	gap: 2rem;
	align-items: flex-start;
}

fieldset {
	border: 1px solid #ccc;
	padding: 15px;
	margin-bottom: 20px;
	border-radius: 6px;
}

legend {
	font-weight: bold;
	padding: 0 10px;
}


.input-separator {
	display: flex;
	gap: 10px;
}

input, select {
	width: 100%;
	padding: 10px;
	margin: 5px 0;
	border: 1px solid #ccc;
	border-radius: 4px;
	font-size: 16px;
}

button {
	width: 100%;
	padding: 10px;
	margin-top: 15px;
	background: #007bff;
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	font-size: 16px;
}

button:disabled {
	background: #ccc;
	cursor: not-allowed;
}

</style>