<template>
    <div :class="'order dot_relative flex ' + ( check_order_picked ? 'picked ' : '' ) +  ( (order.label === true) || order.shipped ? 'label_exist' : '' ) ">
        <div class="order__details flex-80 order__conn"> 
            <span class="order__platform">{{order.platform}}</span>
            <span v-if="order.api_name" class="order__api_name">{{order.api_name}}</span>

            <div  class="order-picked-location">
               <span v-for="x in order.picked_location" v-bind:key="x._id">{{x.parent.name}} - {{ x.name }} </span> 
            </div>

            <!-- order products component -->
            <div class="order__products" >
                <Product v-for="(p) in order.products" v-bind:key="p._id" :item="p" :image="order.Product_images ? order.Product_images.filter(f => { return f.product_id === p.product_id._id })[0] : null"  :class=" (p.picked ? 'picked' : '') & (p.quantity > 1 ? 'multi_order' : '') " />
            </div> 

            <!-- order component -->
            <div v-if="order.notes_color || order.notes_text || order_details_expanded || order.products?.length > 1" class="order__details flex">
                <div class="flex-10">
                    <p class="org_name">{{order.org_name}}</p>
                    <div class="flex-25" style="text-align:center" v-if="orders.filter((x) => { return x.orders[0].orderId === order.orderId } ).length > 1 "> 
                        <img style="width:150px;" :src="duplicateWarning"><br>
                        let op! Dubbel product
                    </div>
                </div>
                
                <div class="flex-10">
                    {{trx.orders["Order ID"]}}
                    <p class="">{{order.orderId}}</p>
                    {{ trx.orders[order?.order_type]?.title }}
                </div>
                
                <div class="flex-10">
                    {{trx.orders["Date"]}}
                    <p>{{moment(order.orderPlacedDateTime).locale("NL").fromNow()}}</p>
                </div>

                <div class="order__shipping hidden-md flex-30" v-if="order.shipmentDetails">
                    <p>
                        {{trx.shipments["Shipment Address"]}}
                    </p>
                        {{ order.shipmentDetails.company }}
                    <br v-if="order.shipmentDetails.company">
                        {{ order.shipmentDetails.firstName }}
                        {{ order.shipmentDetails.surname }}
                    <br>
                        {{ order.shipmentDetails.streetName}}
                        {{ order.shipmentDetails.houseNumber}}
                        {{ order.shipmentDetails.houseNumberExtension}}
                    <br>
                        {{ order.shipmentDetails.zipCode }}
                        {{ order.shipmentDetails.city }} 
                        {{ order.shipmentDetails.countryCode }}
                    <br>
                        {{ order.shipmentDetails.email }}
                    <br/>
                        {{ order.shipmentDetails.deliveryPhoneNumber}}
                </div> 

                <div class="order__remark flex-10">
                    <span class="icon">🏷</span>
                    <span class="title"> {{trx.orders.order_comments}}</span>
                    <textarea :class="'notes '+  order.notes_color " v-model="order.notes_text" v-on:change="setNotes(order)"></textarea>
                    <div class="order__remark__colors">
                        <fieldset>
                            <input type="radio" v-on:change="setNotes(order)" v-model="order.notes_color" value="red" :name="'order'+order._id+'_comments_color'">
                            <input type="radio" v-on:change="setNotes(order)" v-model="order.notes_color" value="green" :name="'order'+order._id+'_comments_color'">
                            <input type="radio" v-on:change="setNotes(order)" v-model="order.notes_color" value="yellow" :name="'order'+order._id+'_comments_color'">
                        </fieldset>
                    </div>
                    <div class="order__remark__email">
                        <input v-model="order.notes_notify" type="checkbox" :id="'order'+order._id+'_notify'" :name="'order'+order._id+'_notify'"> E-mail klant
                    </div>
                </div>

                <div class="order__packaging flex-10">
                    <!-- Shipment method -->
                    <div class="product__ship_method" v-if="shipment_methods_list">
                        <h4 class="product__ship_method--title"><img src="../../assets/icons/delivery_icons/J05.svg" :title="trx.orders['Default shipment']" > </h4>
                        <v-select v-model="order_shipment.method" :options="shipment_methods_list.filter(x => { return x.country_code === order.shipmentDetails.countryCode || x.country_code === undefined })" @input="update_selected_shipment_method({orderId: order._id, code: order_shipment.method && order_shipment.method.code ? order_shipment.method.code : ''})" label="name">
                            <template slot="selected-option" slot-scope="option" >
                                <div style="display: flex; align-items: baseline">
                                    <strong style="margin-right:2px">{{ option.name }} </strong>   € {{ option.price }}
                                </div>
                            </template>
                            <template slot="option" slot-scope="option" >
                                <p>{{option.name}}</p>
                                <small class="text-muted">
                                    € {{ option.price }}
                                </small>
                            </template>    
                        </v-select>   
                    </div>
                    <!-- Packaging -->
                    <div class="product__box_size">
                        <h4 class="product__box_size--title"><img src="../../assets/icons/delivery_icons/A01.svg" :title="trx.orders['Box size']"></h4>
                        <div class="product__box_size__select">
                        <v-select v-if="shipment_boxes_list && shipment_boxes_list.length > 0" v-model="order_shipment.box" :options="shipment_boxes_list" @input="update_selected_packaging_option({orderId: order._id, id: order_shipment.box && order_shipment.box._id ? order_shipment.box._id : ''})" label="label">
                            <template slot="selected-option" slot-scope="option" >
                                <div style="display: flex; align-items: baseline">
                                    <strong style="margin-right:2px">{{ option.label }} </strong>   € {{ option.price }}
                                </div>
                            </template>
                            <template slot="option" slot-scope="option" >
                                <div class="flex"> 
                                    <p class="flex-60">{{option.label}}</p>
                                    <div class="flex-2"> </div>
                                    <small v-if="option?.price > 0"  class="flex-20" style="justify-self: flex-end; text-align: right;">
                                        € {{ option.price }}
                                    </small>
                                </div>
                               
                            </template>    
                        </v-select>
                        </div>
                    </div>
                </div>

                <div class="flex-10">
                    <label for="label_qty">Label quantity</label>
                    <input
                        id="label_qty"
                        type="number"
                        value="1"
                        v-on:change="(e) => changeLabelQuantity(e)"
                    />
                </div>

                <div class="order__actions flex-20 hidden-md">
                    <div class="order_print_buttons">
                        <button v-on:click="downloadPackingList({_id: order._id})">
                            P
                        </button>
                        <button v-on:click="downloadOrderList({_id: order._id})">
                            O
                        </button>
                    </div>
                    <!-- <button v-on:click="startTimer(order)">
                        ►
                    </button>
                    <p> {{ timerCount }} </p> -->
                </div>
            </div>
        </div>
        
       
         
        <!-- shipment component -->
        <div v-if="check_product_pack_option && check_product_ship_option" 
            class="hidden-md order__details__shipment flex-20" :style="order.products ? order.products.filter(x => { return x.cancellationRequest === true }).length > 0 ?  'opacity:0.5; pointer-events: none;' : '' : ''"   >
           
           
            <div v-if="order.platform == 'Bol.com' && check_product_ship_option">
                <div class="bol-shipping" v-if="(SMART_CONTRACT.indexOf(check_product_ship_option.code) < 0) && (order.ship_options && order.ship_options[0]?.handoverDetails)">
                        <span v-if="order.ship_options">Bol.com </span>
                        
                        <div v-if="order.ship_options.filter(f => { return f.transporterCode === order.products[0].product_id.def_shipping_id.code}).length > 0">
                            <div v-for="o in order.ship_options.filter(f => { return f.transporterCode === order.products[0].product_id.def_shipping_id.code  })" v-bind:key="order._id+o.shippingLabelOfferId" >
                                <ShipButton
                                    :o="o"
                                    :ship="ship"
                                    :order-items="order.orderItems"
                                    :order="order"
                                    :code="order.products[0].product_id.def_shipping_id.code.toString()"
                                />
                            </div>
                        </div>
                        <div v-else>
                          
                            <div v-for="o in order.ship_options.filter(ff => {
                                if (ff.labelPrice.totalPrice != 6) {
                                    return ff.transporterCode.includes('TNT') 
                                }
                                })" v-bind:key="order._id+o.shippingLabelOfferId" >
                                <ShipButton
                                    :o="o"
                                    :ship="ship"
                                    :order-items="order.orderItems"
                                    :order="order"
                                    :code="order.products[0].product_id.def_shipping_id.code"
                                />
                            </div>      
                        </div>
                </div>
            </div>

            <br>
            <div class="seperator">

                <div class="smart-shipping" v-if="order.shipmentDetails.countryCode && order.products[0].fulfilment.distributionParty !== 'BOL' ">
                
                    
                    <div class="ship_method ship_method__order" v-if="order.selected_ship_option"> 
                        
                        <div class="" v-for="ship_option in shipment_methods_list" v-bind:key="ship_option._id">

                            <div class="" v-if="(order.selected_ship_option.indexOf('STAMP') > 1 )  && order.selected_ship_option === ship_option.code">
                                Postzegels
                                <stampButton :iframe="iframeRef"  :ship_mtd_code="ship_option.code" :order="order" :code="ship_option.code" :amount="ship_option.code.indexOf('1') > 1 ? 2 : 1" :name="ship_option.name" />
                            </div>

                            <div class="" v-if="((ship_option.product_code > 0) && order.selected_ship_option === ship_option.code) && (ship_option.country_code === order.shipmentDetails.countryCode)">
                                Postnl  
                                <PostNLButton :iframe="iframeRef" :ship_mtd_code="ship_option.code" :order="order" :name="ship_option.name" :code="ship_option.product_code.toString()" />
                            </div>

                            
                        </div>
                         <!-- Custom shipment -->
                       
                            <div class="flex-10" v-if="order.selected_ship_option === 'OWN-SHIPMENT' || 
                                order.selected_ship_option === 'OWN-SHIPMENT'  ">
                                <ownShipmentButton :iframe="iframeRef" ship_mtd_code="OWN-SHIPMENT" :order="order" code="OWN-SHIPMENT" name="Eigen verzendwijze" />
                            </div>
                    </div>
                   
                    <div class="ship_method ship_method__product" v-if="(check_product_ship_option && order.selected_ship_option == undefined) && order.products.length == 1  "> 
                       
                        <div v-for="ship_option in shipment_methods_list" v-bind:key="ship_option._id">
                            
                            <div v-if="( check_product_ship_option.code?.indexOf('STAMP') > 1 )  && check_product_ship_option?.code === ship_option.code">
                                
                                <stampButton :iframe="iframeRef" :ship_mtd_code="ship_option.code" :order="order" :code="ship_option.code" :amount="ship_option.code.indexOf('1') > 1 ? 1 : 2" :name="ship_option.name" />
                            </div>

                            <div v-else-if="(ship_option.product_code > 0) && (check_product_ship_option._id  === ship_option._id) && (ship_option.country_code === order.shipmentDetails.countryCode)">
                                  
                                <PostNLButton :iframe="iframeRef" :ship_mtd_code="ship_option.code" :order="order" :name="ship_option.name" :code="ship_option.product_code.toString()" />
                            </div>
                        </div>
                    </div>
                    
                    <!-- <div :class="(order.selected_ship_option || check_product_ship_option  ? 'has_ship_option' : '' )+'ship_method ship_method__product'" v-if="( check_product_ship_option._id === '624101de115f76ec2466c260') && order.shipmentDetails.countryCode !== 'NL' "> 
                        <div  v-for="ship_option in shipment_methods_list" v-bind:key="ship_option._id">  
                            <div class="" v-if="ship_option.country_code === order.shipmentDetails.countryCode">
                                Postnl    
                                <PostNLButton :iframe="iframeRef" :ship_mtd_code="ship_option.code" :order="order" :name="ship_option.name" :code="ship_option.product_code.toString()" />
                            </div>
                        </div>
                    </div> -->
                    
                    

                    <!--  if the selected ship option is outside of the netherlands, and the default setting is the netherlands, set it to a parcel with the given country -->
                    <div :class="(order.selected_ship_option || check_product_ship_option  ? 'has_ship_option' : '' )+'ship_method ship_method__product'" v-if="  !order.selected_ship_option && ['6246d2c25810b0002a77d69c', '624101de115f76ec2466c260'].includes(check_product_ship_option._id) && order.shipmentDetails.countryCode !== 'NL' "> 
                        <div v-for="ship_option in shipment_methods_list.filter( f =>  {return f.product_code !== 6440 && f.product_code !== 6942 && f.product_code !== 6945 } )" v-bind:key="ship_option._id">
                           
                            <div class="" v-if="ship_option.country_code === order.shipmentDetails.countryCode">
                                Postnl    
                                <PostNLButton :iframe="iframeRef" :ship_mtd_code="ship_option.code" :order="order" :name="ship_option.name" :code="ship_option.product_code.toString()" />
                            </div>
                        </div>
                    </div>

                    <!-- Custom shipment -->
                    <!-- <div class="flex-10" v-if="check_product_ship_option.code === 'OWN-SHIPMENT' || 
                        order.selected_ship_option === 'OWN-SHIPMENT'  ">
                        <br/>
                        <ownShipmentButton :iframe="iframeRef" ship_mtd_code="OWN-SHIPMENT" :order="order" code="OWN-SHIPMENT" name="Eigen verzendwijze" />
                    </div> -->
                </div>

                <div v-if="order.attachments && order.attachments.available" class="attachment-container">
                    <br>
                    Bijlagen <br>
                    <div class="button" @click="downloadAttachments">
                        Download bijlagen
                    </div>
                </div>

            </div>
            
        </div> 
    
        <div v-else class="hidden-md order__details__shipment flex-20">
           <p>Selecteer verzendwijze en verpakking voor verzendopties</p> 
        </div>

        <div class="dot_section">
            <Dots :action="expand_order_details" />
        </div>

    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import Vue from 'vue';

import duplicateWarning from '@/assets/duplicate_warning.svg';
import trx from '@/translations';
import moment from 'moment';

import PostNLButton from './components/PostNLbutton';
import ownShipmentButton from './components/ownShipmentButton.vue';
import stampButton from './components/stampButton';
import ShipButton from './components/ship_button.vue';

import Product from './Product';

import vSelect from 'vue-select';

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'vue-select/dist/vue-select.css';
import Dots from '@/components/Layout/dots.vue';

Vue.component('v-select', vSelect)

/* eslint-disable no-debugger */

export default {
    name: 'Orders',
    computed: {
        ...mapGetters(['orders', 'order_report', 'locations',  'shipping_options', 'shipment_methods_list',  'shipment_boxes_list', 'attachment_files']),
        check_product_ship_option() {
            return this.order.products[0].product_id?.parent ? this.order.products[0].product_id?.parent?.def_shipping_id : this.order.products[0]?.product_id?.def_shipping_id
        },
        check_product_pack_option() {
            return this.order.products[0].product_id?.parent ? this.order.products[0].product_id?.parent?.ship_box_id : this.order.products[0]?.product_id?.ship_box_id
        },
        check_order_picked() {
            const picked = this.order.products.filter(x => {return x.picked_quantity >= x.quantity })  

            const is_picked = picked.length === this.order.products.length ? true : false;
            return is_picked
        },
    },
    methods: {
        ...mapActions([
            'fetch_shipment_boxes_list', 
            'getOrders', 
            'filter_locations', 
            'fetchLocations', 
            'startTimer', 
            'ship', 
            'filterDelivery', 
            'shipPostNL', 
            'setNotes', 
            'getOrderReport',  
            'downloadOrderList',
            'downloadPackingList',
            'toggleDoubleOrders',
            'fetch_shipment_methods_list',
            'get_attachment_files',
            'update_selected_shipment_method',
            'update_selected_packaging_option',
        ]),
        expand_order_details: function() {
            this.order_details_expanded = !this.order_details_expanded
        },
        async downloadAttachments() {
            // Get all attachments from the API
            await this.get_attachment_files(this.order._id)
            
            const files_length = this.attachment_files.length

            let pdfWindow = window.open("", "_blank")
            pdfWindow.document.write("<head><title>Order-" + this.order.orderId + "</title><style>* {font-family: sans-serif;}</style></head><h1 stlyle='font-weight: bold;'>ORDER INSTRUCTIES:</h1><h3>“"
                + this.order.attachments.instructions +
                "”</h3>")
            this.attachment_files.forEach((file, idx) => {
                pdfWindow.document.write(
                    "<h2>Bestand: " + (idx+1) + "/" + files_length + "</h2><h5>" + encodeURIComponent(file.file_name) + "</h5><iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                    encodeURI(file.file_data) + "'></iframe>"
                )
            });
        },
        changeLabelQuantity(e) {
            const order_id = this.order.orderId;
            const quantity = e.target.value;
            
            fetch("/api/order/labels/quantity", {
                method: "POST",
                body: JSON.stringify({
                    order_id: order_id,
                    quantity: quantity
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
        },
    },
    components: {
        PostNLButton,
        stampButton,
        ownShipmentButton,
        Product,
        ShipButton,
        Dots
    },
    created() {
        if (!this.shipment_boxes_list) { 
            this.fetch_shipment_boxes_list();
            this.fetch_shipment_methods_list();
        }
        if (this.order.selected_packaging_option) {
            this.order_shipment.box = this.shipment_boxes_list.find(obj => obj._id === this.order.selected_packaging_option) ? this.shipment_boxes_list.find(obj => obj._id === this.order.selected_packaging_option) : ''
        }
        if (this.order.selected_ship_option) {
            this.order_shipment.method = this.shipment_methods_list.find(obj => obj._id === this.order.selected_ship_option) ? this.shipment_methods_list.find(obj => obj._id === this.order.selected_ship_option) : ''
        }
        
    },
    props: {
        order: Object,
        iframeRef: HTMLIFrameElement,
    },
    data () {
        let startDate = new Date();
        let endDate = new Date();

        return {
            trx,
            moment: moment,
            timerCount: 60,
            duplicateWarning,
            dateRange: {startDate, endDate},
            order_details_expanded: false,
            SMART_CONTRACT: ['SMF-BRIEF-NL', 'SMF-POSTNL-NL', 'SMF-POSTNL-NL', 'SMF-POSTNL-BE', 'SMF-STAMP1-NL', 'SMF-STAMP2-NL', 'SMF-STAMP1-INTL', 'SMF-STAMP2-INTL', 'OWN-SHIPMENT'],
            order_shipment: {
                method: '',
                box: '',
            },
            search: function()  {
                // Declare variables
                var input, filter, ul, li, a, i, txtValue;
                input = document.getElementById('myInput');
                filter = input.value.toUpperCase();
                ul = document.querySelector(".orders");
                li = ul.querySelectorAll('.order__location');

                // Loop through all list items, and hide those who don't match the search query
                for (i = 0; i < li.length; i++) {
                    a = li[i].querySelector(".searchable");
                    
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    li[i].style.display = "";
                    } else {
                    li[i].style.display = "none";
                    }
                }
            }
        }
    },
    filters: {
      date(val) {
        return val ? val.toLocaleString() : ''
      }
    }
}

</script>

<style lang="scss" scoped >
    .order {
        @include box-bg-white;
        &__shipping {
            max-width: 15rem;
            display: inline-block;  
            width: 200px;  
            white-space: nowrap;  
            overflow: hidden;   
            text-overflow: ellipsis; 
        }
    }
    .order_print_buttons {
        height: 3rem;
        width: fit-content;
        display: flex;
        position: absolute;
        bottom: 0px;
        left: 0px;
    }

    .order_print_buttons button {
        @include button;
        background: var(--color-grey-dark-2);
        height: 3rem;
        width: 3rem;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding: 0.5rem;
        font-size: 1.5rem;
        margin-right: 0.5rem;
    }

    .multi_order {
        @include box-bg-red-dash;
        border-width: 0.5rem !important;
    }

    .label_exist {
        *:not(.notes, textarea) {
            pointer-events: none !important;
        }
        background: var(--color-red) !important;
    }

    .picked {
        background: var(--color-green);
    }

   .dot_relative {
    position: relative;
   }

    .dot_section {
        position: absolute;
        right: -2rem;
        top: 5rem;
    }

    .has_ship_option {
        display: none;
        * {
            opacity: 0.5;
        }
    }

    .order-picked-location {
        position: absolute;
        top: -7rem;
        right: 2rem;
        span {
            padding: 1rem;
            @include box-bg-dash;
            font-size: 3.2rem;
            font-weight: 500;
            margin-left: 2rem;
        }
    }

    .attachment-container {
        align-self: flex-start;
    }

    .button {
        text-align: center;
    }

    .seperator {
        gap: 1rem;
    }

    .product__ship_method {
      position: relative;
      width: 100%;

      position: relative;
      width: calc(100% - 4rem);
      margin-left: 4rem;

      &--title {
       position: absolute;
       width: 5rem;
       z-index: 999;
       left: -3.8rem;
       display: flex;
       align-content: center;
       align-items: center;
       justify-content: center;
       height: 4rem;
       border-top-left-radius: var(--border-radius);
       border-bottom-left-radius: var(--border-radius);
       background-color: var(--color-primary-light);
       img {
        width: 3.4rem;
       }
      }
      &--value {
          position: absolute;
          top: 3.7rem;
          left:2rem;
          z-index: 99;
      }
    }

    .product__box_size {
      
      position: relative;
      width: calc(100% - 4rem);
      margin-left: 4rem;

        &--title {
            position: absolute;
            width: 5rem;
            z-index: 999;
            left: -3.8rem;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            height: 4rem;
            border-top-left-radius: var(--border-radius);
            border-bottom-left-radius: var(--border-radius);
            background-color: var(--color-primary-light);
            img {
                width: 3.4rem;
            }
        }
     
     &--value {
          position: absolute;
          top: 3.7rem;
          left:2rem;
     }
   }
</style>