<template>
  <div class="flex flex-100">
    <button class="button flex-100" v-on:click="() => isPopupOpen = !isPopupOpen">Niet voldoende aantal
      op locatie</button>
      <div v-if="isPopupOpen" class="popup_pick">
        <label>Hoeveel stuks zijn er op locatie?</label>
        <input type="number" v-model.number="inputNumber" required />
        <button class="danger_button" v-on:click="changeQty"> Verander aantal </button>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      isPopupOpen: false,
      inputNumber: 0
    }
  },
  computed: {
    ...mapGetters(['pick_list'])
  },
  methods: {
    ...mapActions(['delete_pick_location', 'submit_popup_pick']),
    pop_up_pick() {
      this.isPopupOpen = true;
    },
    changeQty() {
      // Parse the input number
      let available_amount = parseInt(this.inputNumber);
      this.pick_list[0].qty = available_amount;
    }
  }
}
</script>

<style lang="scss" scopedlang="scss" scoped>
.popup_pick {
  position: relative;
  // bottom: -11rem;
  // right: 2rem;
  color: var(--color-white);
  background: var(--color-black);
  padding: 2px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-light);
  z-index: 1000;

  &__conn {
    position: relative;
  }

  button {
    width: 100%;
    margin-bottom: 1rem;
  }
}
button {
  margin-block: 1rem;
  width: 100%;
}
</style>