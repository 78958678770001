<template>
    <div>
      <vue-tel-input
        v-model="value"
        :defaultCountry="schema.defaultCountry || ''"
        :mode="schema.mode || 'auto'"
        :preferredCountries="schema.preferredCountries || []"
        @input="updateModel"
      />
    </div>
  </template>
  
  <script>
  import { abstractField } from "vue-form-generator";
//   import VueTelInput from "vue-tel-input";
  
  export default {
    name: "field-phone",
    mixins: [abstractField], // Required for custom VFG fields
   
    methods: {
      updateModel(value) {
        this.value = value; // Sync input value with VFG model
        this.$emit("model-updated", this.value);
      }
    }
  };
  </script>

<style lang="scss" >
    @import 'https://unpkg.com/vue-tel-input@6.0.5/dist/vue-tel-input.css';
</style>
  