import nl from './dutch';
import en from './english';
// import zh from './chinese';
// import fr from './french';
// import it from './italian';
// import ar from './arabic';
// import hy from './armenian';
/* eslint-disable no-debugger */

// Function to get the language based on the current user's preference
const getLanguage = () => {
  let language = navigator.language || navigator.userLanguage;
  language = language.split('-')[0].toLowerCase();
  console.log(language);

  switch (language) {
    case 'nl':
      return nl;
    case 'en':
      return en;
    default:
      return en;
  }
}

export default getLanguage();