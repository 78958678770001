<template>
  <div class="signupform-container" @keyup.enter="handleEnter">
        <h1>{{trx.users['Login']}}</h1> 
        <vue-form-generator :schema="schema" :model="model" :options="formOptions"></vue-form-generator>
        <router-link :to="'signup'" class="form-link">{{trx.users['Not a user? Signup!'] }} </router-link>
        <router-link :to="'reset'" class="form-link">{{trx.users.reset_password}}</router-link>

  </div>
</template>

<script>
import Vue from 'vue';
import VueFormGenerator from "vue-form-generator";
import { mapGetters, mapActions } from 'vuex';
import trx from '@/translations';

Vue.use(VueFormGenerator)

export default {
  name: 'AuthContainer',
  methods: {
    ...mapActions(['fetchUser', 'login',]),
    handleEnter(event) {
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent unintended form submission
        this.login(this.model);
      }
    }
  },
  computed: mapGetters(['user']),
  components: {
    "vue-form-generator": VueFormGenerator.component,
  },
  mounted() {
    document.addEventListener("keyup", this.handleEnter); // Listen for Enter globally
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleEnter); // Cleanup event listener
  },
  data () {
    return {
      trx,
      isValid: false,
      model: {
        name: '',
        password: '',
        email: '',
      },
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'email',
            label: trx.users['Email'],
            model: 'email'
          },
          {
            type: 'input',
            inputType: 'password',
            label: trx.users['Password'],
            model: 'password',
            required: true
          },
          
          {
            type: 'Submit',
            buttonText: 'Login',
            onSubmit: this.login,
            validateBeforeSubmit: true
          }
        ]
      },
      formOptions: {
        // validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .vue-form-generator fieldset {
      display: flex;
      flex-direction: column !important;
  }
</style>
