export default {
  date_format: "DD-MM-YY",
  date_time_format: "DD-MM-YYY HH:MM",
  time_format: "HH:MM",
  delete: "Delete",
  continue: "Continue",
  update: "Update",
  "Show preview": "Show preview",
  "Data preview": "Data preview",
  "Select a file to start import": "Select a file to start importing",
  Total: "Total",
  Count: "Count",
  "Sub total": "Subtotal",
  Discount: "Discount",
  Add: "Add",
  Import: "Import",
  Date: "Date",
  "Track & Trace": "Track & Trace",
  Phone: "Phone",
  "Click here your select an Excel file": "Click here to select an Excel file",
  "Something went wrong, please conduct your logs": "Something went wrong, please check your logs",
  Sender: "Sender",
  Email: "Email",
  Location: "Location",
  Comment: "Comment",
  Amount: "Amount",
  search: "Search",
  expand: "Expand",
  condense: "Condense",
  save: "Save",
  read: "Read",
  pick_note: "Pick note",
  limit: "Limit",
  refresh: "Refresh",
  import: "Import",
  "Nothing selected": "Nothing selected",
  common: {
    "name": "Name",
    "search_results": "Search Results",
    "select_date_time": "Select Date & Time"
  },
  admin: {
    name: "Admin settings",
    search_orderid: {
      name: "Search order ID",
    },
    ship_boxes: {
      name: "Shipping boxes & dimensions",
      max_size: "Maximum dimension (l,w,h)",
      package: "Package",
      product_size: "Product size",
      package_cost: "Packaging costs",
      purchase_cost: "Purchase costs",
      package_code: "Item number",
      fulfillment_cost: "Fulfillment costs",
      location: "Location",
      create: "Create a new package type",
    },
    labels: {
      name: "Labels for scanning",
    },
    locations: {
      name: "Location settings",
    },
    shipment_method: {
      name: "Shipping options",
      code: "Code",
      price: "Price",
      purchase_price: "Purchase price",
      cut_off: "Cut-off",
      late_drop_surcharge: "Evening surcharge",
      peak_surcharge: "Peak surcharge",
    },
    surcharge: {
      name: "Surcharges",
      code: "Code",
      price: "Price",
      note: "Note",
      type: "Type",
    },
    users: {
      name: "Users & groups",
      password_hint: "Minimum 9 characters",
    },
    transfers: {
      name: "All open transfers",
    }
  },
  settings: {
    apis: "Integrations",
    Settings: "Settings",
    "Your organization": "Your organization",
    Subscription: "Subscriptions",
    Users: "Users",
    invoice: {
      total_used_packaging: "Packaging",
      total_used_shipment_method: "Postage stamps",
      total_used_surcharge: "Surcharges",
      total_fulfilment: "Fulfillment",
      total: "Total",
      download_details: "Download details",
      "rack-box": "Shelf location(s)",
      "half-box": "Half shelf location(s)",
      warehousing: "Warehouse location(s)",
      pallet: "Pallet location(s)",
      "1/2pallet": "Half pallet location(s)",
      "1/3pallet": "Third pallet location(s)",
    },
  },
  organizations: {
    // General Organization Information
    name: "Stores",
    organization: "Store",
    "Organization address": "Business Address",
    description: "An overview of all your stores",
    "Organization name": "Store Name",
    "Your organization details": "Your store details",
    type: "Store type",
    about: "About",
    coc_number: "Chamber of Commerce Number",
    vat_number: "VAT Number",
    surcharges: "Surcharges",
    "Contact Person": "Contact Person",
    "Contact Person Email": "Contact Person Email",
    delete_org_help: "Danger zone, delete your store",
    Phone: "Phone",
    Branche: "Sector",
    "Company Information": "Company Information",
    "Please fill out your basic company details.": "Please fill out your basic company details.",
    "Website URL": "Website URL",
    Industry: "Industry",
    "Nothing selected": "Nothing selected",

    // Industries (for the Industry dropdown)
    Industries: [
      { id: "accessories", name: "Accessories" },
      { id: "automotive", name: "Automotive" },
      { id: "baby_child", name: "Baby/Child" },
      { id: "books_software", name: "Books/Software" },
      { id: "cosmetics_personal_care", name: "Cosmetics/Personal Care" },
      { id: "electronics_hardware", name: "Electronics/Hardware" },
      { id: "fashion", name: "Fashion" },
      { id: "food_beverages", name: "Food/Beverages" },
      { id: "health_supplements_vitamins", name: "Health/Supplements/Vitamins" },
      { id: "home_garden_pets", name: "Home/Garden/Pets" },
      { id: "jewelry", name: "Jewelry" },
      { id: "shoes", name: "Shoes" },
      { id: "sports_hobbies_games", name: "Sports/Hobbies/Games" },
      { id: "textiles_bedding", name: "Textiles/Bedding" },
      { id: "other", name: "Other" }
    ],

    "Company Logo": "Company Logo",
    "Upload VAT Exemption": "Upload VAT Exemption",
    "Contact Information": "Contact Information",
    "Provide the details of your main contact person.": "Provide the details of your main contact person.",
    Contacts: "Contacts",
    "Full Name": "Full Name",
    Email: "Email",
    Role: "Role",
    General: "General",
    Finance: "Finance",
    Technical: "Technical",
    Support: "Support",
    "Invite for user account": "Invite for user account",

    // SKU Options
    "Number of SKUs": "Number of SKUs",
    SKUs: [
      { id: "lt_50", name: "Less than 50" },
      { id: "50_100", name: "50 to 100" },
      { id: "100_500", name: "100 to 500" },
      { id: "500_1000", name: "500 to 1,000" },
      { id: "1000_5000", name: "1,000 to 5,000" },
      { id: "gt_5000", name: "More than 5,000" }
    ],

    // Annual Order Volume Options
    "Annual Order Volume": "Annual Order Volume",
    OrderVolume: [
      { id: "lt_1000", name: "Less than 1,000" },
      { id: "1000_5000", name: "1,000 to 5,000" },
      { id: "5000_10000", name: "5,000 to 10,000" },
      { id: "10000_20000", name: "10,000 to 20,000" },
      { id: "20000_50000", name: "20,000 to 50,000" },
      { id: "50000_100000", name: "50,000 to 100,000" },
      { id: "100000_200000", name: "100,000 to 200,000" },
      { id: "200000_500000", name: "200,000 to 500,000" },
      { id: "gt_500000", name: "More than 500,000" }
    ],

    // Country Options
    "Shipping Origin Country": "Shipping Origin Country",
    Countries: [
      { id: "NL", name: "Netherlands" },
      { id: "CN", name: "China" },
      { id: "DE", name: "Germany" },
      { id: "BE", name: "Belgium" },
      { id: "UK", name: "United Kingdom" },
      { id: "OTH", name: "Other" }
    ],

    // Carrier Options
    "Preferred Shipping Carrier": "Preferred Shipping Carrier",
    Carriers: [
      { id: "dhl", name: "DHL" },
      { id: "postnl", name: "PostNL" },
      { id: "dpd", name: "DPD" },
      { id: "ups", name: "UPS" },
      { id: "fedex", name: "FedEx" },
      { id: "other", name: "Other" }
    ],

    // Storage & Warehousing
    "Storage & Warehousing": "Storage & Warehousing",
    "How many pallets do you store on average?": "How many pallets do you store on average?",
    "Storage Duration": "Storage Duration",
    StorageDuration: [
      { id: "short_term", name: "Short (1-3 months)" },
      { id: "medium_term", name: "Medium (3-12 months)" },
      { id: "long_term", name: "Long (12+ months)" }
    ],
    "How often do you rotate/change pallets?": "How often do you rotate/change pallets?",
    PalletRotation: [
      { id: "daily", name: "Daily" },
      { id: "weekly", name: "Weekly" },
      { id: "monthly", name: "Monthly" },
      { id: "rarely", name: "Rarely" }
    ],

    // Returns & Reverse Logistics
    "Returns": "Returns",
    "Define your return handling policies to ensure smooth processing.": "Define your return handling policies to ensure smooth processing.",
    "Do you accept returns? And should we handle them?": "Do you accept returns? And should we handle them?",
    "Yes": "Yes",
    "No": "No",
    "How should we handle your defect returns?": "How should we handle your defect returns?",
    "Keep the returns on a pallet location": "Keep the returns on a pallet location",
    "Destroy them": "Destroy them",
    "How should we handle your sellable returns?": "How should we handle your sellable returns?",
    "Inbound them to same location as normal products": "Inbound them to the same location as normal products",
    "Inbound them to other location as return deal": "Inbound them to another location as a return deal",
    "Keep the return on a different pallet location": "Keep the return on a different pallet location",
    "Do you require original packaging for sellable returns?": "Do you require original packaging for sellable returns?",
    "Return Window (How many days after purchase can customers return items?)": "Return Window (How many days after purchase can customers return items?)",
    "7 days": "7 days",
    "14 days": "14 days",
    "30 days": "30 days",
    "60 days": "60 days",
    "90 days": "90 days",
    Custom: "Custom",
    "Who covers return shipping costs?": "Who covers return shipping costs?",
    Customer: "Customer",
    "Business covers it": "Business covers it",
    "Depends on reason": "Depends on reason",
    "How do you process returns?": "How do you process returns?",
    Refund: "Refund",
    "Store Credit": "Store Credit",
    Exchange: "Exchange",
    "What are the conditions for accepting a return?": "What are the conditions for accepting a return?",
    "Estimated Processing Time for Returns": "Estimated Processing Time for Returns",
    "Support Email for Return Inquiries": "Support Email for Return Inquiries",
    "Phone Number for Return Support": "Phone Number for Return Support",

    // Growth Path
    "Growth path": "Growth path",
    "Tell us more about your ambitions.": "Tell us more about your ambitions.",
    "Expected Order Growth in the Next 12 Months": "Expected Order Growth in the Next 12 Months",
    GrowthPath: [
      { id: "stable", name: "Stable" },
      { id: "10_20_increase", name: "10-20% increase" },
      { id: "20_50_increase", name: "20-50% increase" },
      { id: "gt_50_increase", name: "More than 50% growth" }
    ],
    "Do you have any expansion plans?": "Do you have any expansion plans?",

    // Address & Billing
    "Address & Billing": "Address & Billing",
    "Company address": "Company address",
    "Billing address": "Billing address",
    notSameBilling: "Billing address is different from company address",
    notSameBilling_email: "Billing email is different from company email",
  },
  users: {
    Name: "Name",
    Email: "Email",
    Login: "Login",
    "First name": "First name",
    "Last name": "Last name",
    Password: "Password",
    Phone: "Phone number",
    Licence: "License",
    Verified: "Verified",
    Signup: "Sign up",
    "Create a new user": "Create a new user",
    "Users in your organization": "Team members in your organization",
    "Import users": "Import users",
    "Stakeholder Group": "Stakeholder group",
    "Already have an account, login": "Already have an account? Login",
    "Signup to get started": "Sign up to get started",
    "Not a user? Signup!": "Not a user? Sign up now!",
    reset_password: "Forgot password?",
    reset: "Reset password",
    "Please enter your email": "Please enter your email",
    "Know your password?, Login": "Know your password? Login!",
    adress_hint: "Enter your address, use a comma to separate postcode and place",
  },
  plans: {
    name: "Plans",
  },
  subscriptions: {
    name: "Prices",
    Standard: "Standard",
    "Discount for yearly payment": "Discount for yearly payment",
    "Terms of Service": "Terms of Service",
    "Your current subscription": "Your current subscription",
    "I have read and accept the terms of condition":
      "I have read and agree to the terms and conditions.",
    "CSR Managers": "CSR Managers",
    free_plan_help: "",
    Stakeholders: "Stakeholders",
    "Select your plan": "Select your plan",
    Enterprise: "Enterprise",
    "Start for free": "Start for free",
    "Price / order": "Price per order",
    Invoices: "Invoices",
    description: "Shows an overview of all available subscriptions",
    new: "New subscription",
    "Pricing knowledge": "Pricing knowledge",
  },
  shops: {
    "Create a new Shop": "Create a new store",
    Shops: "Stores",
  },
  products: {
    button_options_name: "Product options",
    button_link_name: "Product links",
    Product: "Product",
    ProductName: "Product name",
    "Add inventory": "Add inventory",
    "Inventory checkin": "Check-in",
    "Sync with bol.com": "Sync with bol.com",
    search_placeholder: "Zoek product op naam of EAN",
    Note: "Note",
    "Return instruction": "Return instruction",
    sf_stock: "Smart inventory",
    total_stock: "Total stock",
    active_stock: "Available",
    lvb_stock: "LVB stock",
    warehouse_stock: "Warehouse stock",
    import_your_products: "Import your products here",
    create: "Add new product",
    note: "Note",
    ean: "EAN",
    search: "Search product by name or EAN",
    pick_note: "Pick note",
    pack_note: "Pack note",
    total_sold_30d: "Sold 30 days",
    customs: {
      button_name: "Customs",
      settings: "Customs settings",
      customs: "Customs (Shipments outside EU)",
      hs: "HS Code",
      value: "Value in euros",
      weight_kg: "Weight in kilograms",
    },
    recount: {
      button_name: "Recount",
      recounting: "Recounting",
      settings: "Recount settings",
    },
    import: {
      add_product: "⥊ Add product",
      xlsx: "⥊ Excel",
      barcode_sku_ean: "EAN / SKU / barcode",
      Product_name: "Product",
      fullfilled_bysf: "On/Off",
      length: "Length",
      height: "Height",
      width: "Width",
      weight_kg: "Weight (kg)",
    },
    sync: {
      bol: "Bol.com",
      woo: "WooCommerce",
      shopify: "Shopify",
      mirakl: "Mirakl",
      lightspeed: "Lightspeed",
      shopware: "Shopware",
      channelengine: "Channel Engine",
      excel: "⥊ eXcel",

      help: "Use these buttons to import products that you have published on different platforms.",
      import_products: "Import",
    },
  },
  inventory: {
    "Pre-announced": "Pre-registered",
    delivered: "Delivered",
    Box: "Box",
    Boxes: "Boxes",
    Pallet: "Pallet",
    Pallets: "Pallets",
  },
  returns: {
    name: "Returns",
    return_number_or_barcode: "Return number or barcode",
    scan_help_text: "Enter a return number or scan a barcode",
    location: "Location",
    product: "Product",
    return_id: "Return number",
    return_instruction: "Return instruction",
    damage: "Damage",
    damaged_package: "Damaged package",
    damaged_product: "Damaged product",
    damaged_product_package: "Damage to product packaging",
    registered: "Registration date",
    product_state: "Product condition",
    reason: "Customer/Consumer return reason",
    customer_details: "Consumer details",
    no_returns_to_show: "No returns to display",
    handel_return_close: "Complete & close",
    RETURN_RECEIVED: "Return received",
    EXCHANGE_PRODUCT: "Exchange product",
    RETURN_DOES_NOT_MEET_CONDITIONS: "Return does not meet conditions",
    REPAIR_PRODUCT: "Repair return",
    CUSTOMER_KEEPS_PRODUCT_PAID: "Customer keeps the product",
    STILL_APPROVED: "STILL APPROVED",
    product_excellent: "Product looks neat and can be resold",
    product_good: "Product fairly ok, can go back on shelf",
    product_bad_state: "Product/packaging damaged cannot be sold",
  },
  orders: {
    "Order ID": "Order ID",
    order: "Order",
    multi_orders: "Multi orders",
    warning_orders: "Warning orders",
    Date: "Date",
    "Add order": "Add order",
    "Your orders": "Your orders",
    Orders: "Orders",
    Status: "Status",
    Create: "Place new order",
    Shipments: "Shipped",
    Products: "Products",
    Receiver: "Receiver",
    name: "Name",
    last_name: "Last name",
    streetname: "Street name",
    housenumber: "House number",
    addition: "Addition",
    postalcode: "Postcode",
    place: "Place",
    city: "City",
    country: "Country",
    company: "Company name",
    "Sync with bol.com": "Sync with bol.com",
    "Send products to us": "Send products to us",
    EAN: "EAN",
    "Delivery time": "Delivery time",
    "Own stock": "Own stock",
    "SF stock": "Our stock",
    Stock: "Stock",
    "Fulfil by SF": "Processed by SF",
    "Fulfilled by BOL (FBB)": "Fulfilled by BOL (FBB)",
    FBB: "FBB",
    SF: "SF",
    "Add inventory": "Add inventory",
    "Send your products to us": "Send your products to us",
    "Default shipment": "Default shipping method",
    Location: "Location",
    "Box size": "Box dimension",
    email: "Email",
    phone: "Phone",
    "Select products you want to send": "Select the products you want to send",
    "Wrong product": "Wrong product",
    "Wrong quantity": "Wrong quantity",
    "Damaged product": "Damaged product",
    "Webshop order": "Webshop order",
    order_comments: "Order notes",
    import_your_orders: "Import your orders here",
    shipping_info: "Shipping details",
    confirmation: "Confirmation",
    product_selector_text: "Select the products you want to send",
    products_in_order: "Products in this order",
    to_sending: "To shipping details",
    to_confirm: "To confirm",
    to_create: "Confirm and send",
    shipping_method: "Shipping method",
    attachment: "Attachment (PDF only)",
    attachment_placeholder: "Instructions for handling the attachment",
    send: "Order created",
    import: {
      order_id: "Order ID",
      product_name: "Product",
      quantity: "Quantity",
      city: "City",
      countryCode: "Country code",
      email: "Email",
      firstName: "First name",
      surName: "Surname",
      houseNumber: "House number",
      houseNumberExtension: "House number extension",
      language: "Language",
      salutation: "Salutation",
      streetName: "Street name",
      zipcode: "Postcode",
      deliveryPhoneNumber: "Phone number",
      reason: "Reason",
      notes_text: "Note",
      notes_color: "Note color",
      company: "Company"
    },
    "add_order": "Add order",
    "order_type": "What type of order would you like to create?",
    "manual_order": {
      "title": "Manual order",
      "description": "Create a new order for shipment based on a specific reason, such as a missing or incorrect delivery.",
      "reasons": {
        "reason": "Reasons for manual order",
        "received": "Order not received by PostNL",
        "incorrect": "Incorrect product",
        "quantity": "Wrong quantity",
        "damaged": "Damaged product",
        "webshop": "Webshop order"
      }
    },
    "b2b_order": {
      "title": "B2B order",
      "description": "Create a new business order for shipping to a B2B customer or distributor."
    },
    "amazon_fba_order": {
      "title": "Amazon | FBA order",
      "description": "Create a new shipment for Amazon Fulfillment (FBA) and ensure that your products are correctly delivered to an Amazon fulfillment center."
    },
    "bol_lvb_order": {
      "title": "Bol.com | LVB order",
      "description": "Create a new shipment for the Bol.com Logistics via Bol (LVB) program and follow the guidelines for proper delivery."
    }
  },
  pick_list: {
    name: "Pick list",
    qty: "Quantity",
  },
  shipments: {
    "sent_orders": "Sent Orders",
    "24h": "24 hours",
    "> 2 days": "> 2 days",
    "on_the_way": "On the Way",
    "not_sorted": "Not Sorted",
    "ordered_by": "Ordered By",
    "order_date": "Order Date",
    "sent_date": "Sent Date",
    "delivery_date": "Delivery Date",
    "shipment_method": "Shipment Method",
    "day_delivered": "< 24 Hours Delivered",
    "two_day_delivered": "< 48 Hours Delivered",
    "search_placeholder": "Search by name, order or zipcode",
    "Shipment Address": "Shipment Address",
    "Amount": "Amount",
    "Ready for shipment": "Ready for Shipment",
    "Packed": "Packed",
    "Shipped": "Shipped",
    "Shipment options": "Shipment Options",
    "Open": "Open",
    "Pallets": "Pallets",
    "Coli": "Coli/Boxes",
    "Type": "Type",
    "remark": "Remark"
  },
  checkinv: {
    created_at: "Shipped",
    expected_delivery: "Expected",
    status: "Status",
    checked_in_date: "Checked in",
    product_name: "Product",
    product_ean: "Ean/SKU",
    expected: "Expected",
    final_check_in: "Checked in",
    comment: "Comment",
    pallets: "Pallets",
    pallet: "Pallet",
    box: "Box",
    boxes: "Boxes",
    change_checkin: "View/modify check-in",
    search: "Search check-in by name",
    edit_checkin: "View/modify check-in",
    received: "Received",
    add_checkin: {
      overview: "Overview",
      out_of_stock: "Out of stock",
      application: "Application",
      reason: "What do you want to do?",
      stock_checkin: "Stock check-in",
      stock_checkin_info: "Click here to inform us about your planned shipments, so we can optimally prepare for the receipt and storage, and carefully manage your goods.",
      change_checkin: "View/modify check-in",
      change_checkin_info: "View or edit your previously reported shipments to keep the details up-to-date and ensure a seamless logistics experience.",
      type: "Type",
      products: "Products",
      data: "Data",
      confirmation: "Confirmation",
    },
    type: {
      package: "Package shipment",
      package_text: "Choose this option for registering smaller, individually packaged items. Delivered by PostNL, UPS, DpD, or other courier services",
      pallet: "Pallet shipment",
      pallet_text: "Select this for larger shipments on pallets for organized transport.",
      container_other: "Container shipment / others",
      container_other_text: "For registering shipments in containers or non-standard sizes.",
    },
    checkin_products: {
      choose_products_in_box: "Choose the product that is in this box",
      no_products: "You do not have any products in our system yet, please do this first",
      search_products: "Search product on name or EAN",
    },
    checkin_product: {
      unit_amount: "Number of units (boxes)*",
      amount: "Amount",
      box_amount: "Quantity in box*",
      total_amount: "Total number of pieces (CE)",
      expiration_date: "Expiration date",
      batch_number: "Batch number",
      add: "Add",
      divergent: "* Is one box different? Complete this step with the matching boxes and then add the same product again, in the previous step.",
      check: "Check if your products are set up correctly",
      back: "Back",
      totals: "Totals",
      per_box: "Products per box",
      boxes: "Boxes",
      submit_checkin: "Confirm check-in",
    },
    checkin_success: {
      shipment_instructions: "Shipping instructions:",
      documents_sent: "The documents have been sent by email. You can also download them directly from here",
      checkin_step1: "Download the packing slip and put it in the package",
      checkin_step2: "Then download the QR code and stick it on the outside of the package",
      checkin_important: "Important: this QR code will check in your package. If it is missing, the check-in process will be delayed."
    },
    checkin_information: {
      type: "Indicate where the products are",
      products: "Choose one by one the products you want to add",
      information: "Fill in the information below to complete the check-in",
    },
    checkin_confirmation: {
      contact_person_label: "Contact Person",
      contact_person_placeholder: "Contact Person",
      contact_person_email_label: "Contact Person Email",
      contact_person_email_placeholder: "Contact Person Email",
      phone_label: "Phone Number",
      phone_placeholder: "Phone",
      shipping_address_label: "Shipping Address",
      shipping_address_placeholder: "Shipping Address",
      track_trace_label: "Track & Trace",
      track_trace_placeholder: "Track & Trace",
      expected_delivery_label: "Expected Delivery",
      expected_delivery_placeholder: "Expected Delivery",
      button_submit: "Create Registration"
    },
    "checkin_done": {
      confirmation_message: "Your registration has been received!",
      email_instructions: "The documents have been sent via email. You can also download them directly from here.",
      shipping_instructions_title: "Shipping Instructions:",
      shipping_instruction_1: "Ensure that the products are packaged according to our delivery requirements",
      shipping_instruction_2: "Then download the QR code and stick it on the outside of the box.",
      important: "Important:",
      important_note: "This QR code will check in your package. If it is missing, your shipment will not be accepted and will be returned to the supplier.",
      view_information_button: "View Information",
      documents_per_product: "Documents per Product",
      box_label: "Box",
      packing_slip_label: "Packing Slip",
      qr_code_label: "QR Code",
      download_all_documents_button: "Download All Documents",
      processing_shipment: "We are processing your registration. Stay on this page!"
    },
  },
  inbound_details: {
    delivery: {
      delivery: "Delivery",
      expected: "Expected",
      delivered: "Delivered",
      organisation: "Organization",
      contactInformation: "Contact information",
      units: "Units",
      packedPer: "Packed per",
      transit: "In transit"
    },
    location: {
      location: "Location",
      expirationDate: "Expiration date",
      isPackageClosed: "Has the package arrived closed",
    },
    extraWork: "Additional work & surcharges",
    note: "Notes"
  },
  history_locations: {
    button_name: "Historical locations",
    name: "Historical locations of product",
    none: "No historical locations found",
  },
  stock_analysis: {
    button_name: "Stock analysis",
    analysis: "Analysis",
    checked_in: "Checked in",
    date: "Date",
    amount: "Quantity",
    retours: "Returns",
    period: "Period",
    orders: "Orders",
    logical_stock: "Logical stock",
  },
  automations: {
    button_name: "Automations",
  },
  validation_trx: {
    fieldIsRequired: "This field is required!",
    invalidFormat: "Invalid format!",
    numberTooSmall: "The number is too small! Minimum: {0}",
    numberTooBig: "The number is too big! Maximum: {0}",
    invalidNumber: "Invalid number",
    invalidInteger: "The value is not an integer",
    textTooSmall:
      "The length of the text is too small! Current: {0}, Minimum: {1}",
    textTooBig: "The length of the text is too large! Current: {0}, Maximum: {1}",
    thisNotText: "This is not text!",
    thisNotArray: "This is not an array!",
    selectMinItems: "Select at least {0} items!",
    selectMaxItems: "Select no more than {0} items!",
    invalidDate: "Invalid date!",
    dateIsEarly: "The date is too early! Current: {0}, Minimum: {1}",
    dateIsLate: "The date is too late! Current: {0}, Maximum: {1}",
    invalidEmail: "Invalid email address!",
    invalidURL: "Invalid URL!",
    invalidCard: "Invalid card format!",
    invalidCardNumber: "Invalid card number!",
    invalidTextContainNumber:
      "Invalid text! Should not contain numbers or special characters",
    invalidTextContainSpec:
      "Invalid text! Should not contain special characters",
    add_product_name: "Add product name",
    add_ean: "Add EAN",
    invalid_product_items: "Add at least one product",
    invalid_streetname: "Add street name",
    invalid_housenumber: "Add house number",
    invalid_postcode: "Add postcode",
    invalid_country: "Add country",
    invalid_ship_option: "Choose shipping option",
    invalid_type: "Choose why you are creating this order",
    invalid_email: "Add email",
    invalid_EAN_input: "Invalid EAN, must contain 13 digits",
    invalid_phone: "Add phone number",
    invalid_firstname: "Add name",
    invalid_surname: "Add surname",
    invalid_company: "Add company name",
    invalid_city: "Add city",
    invalid_hs: "Add an HS Code",
    invalid_value: "Add a value",
    invalid_weight: "Add a weight",
    invalid_shipment_method: "Choose a shipping option",
    invalid_attachment_text: "Add a note for your attachment",
    invalid_attachment_files: "Add an attachment if you have instructions",
  },
};
