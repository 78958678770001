<template>
  <div class="wizard-container">
    <!-- Minimal Step Indicator -->
    <div class="steps-indicator">
      <span
        v-for="(stepItem, idx) in wizardSteps"
        :key="idx"
        :class="['step-dot', { active: currentStep === idx }]"
      >
        Step {{ idx + 1 }}
      </span>
    </div>

    <!-- Vue Form Generator for the CURRENT step only -->
    <vue-form-generator
      :schema="activeSchema"
      :model="FormModel"
      :options="formOptions"
      ref="vfgForm"
    />

    <div class="wizard-nav">
      <!-- Navigation Buttons -->
      <button v-if="currentStep > 0" @click="prevStep">Previous</button>

      <button class="button btn-primary"
        v-if="currentStep < wizardSteps.length - 1"
        @click="nextStep"
      >
        Next
      </button>

      <!-- Final step shows Submit button -->
      <button v-else @click="onSubmitForm">
        {{ submitNmae || 'Submit' }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import trx from '@/translations'
import VueFormGenerator from 'vue-form-generator';
import 'vue-form-generator/dist/vfg.css'


export default {
  name: 'organization-form',

  props: {
    // Optional callback from parent
    submit: Function,

    // Custom button text prop (user named it "submitNmae")
    submitName: String,

    // Incoming model object
    model: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    // Provide defaults for the fields in the form
    const defaults = {
      vat_id: '',
      coc_number: '',
      name: '',
      website: '',
      address: {
        street: '',
        houseNumber: '',
        postalCode: '',
        city: ''
      },
      billing_address: '',
      sameBilling: false,
      contacts: [
      ],
      free_trial: false
    }

    // Merge user-provided model with defaults
    const initialFormModel = Object.assign({}, defaults, this.model)

    return {
      FormModel: initialFormModel,
      formOptions: {
        validateAfterChanged: true,
        validateAsync: true
      },

      // Wizard step control
      currentStep: 0,

      // Wizard steps
      wizardSteps: [
  // STEP 1: Basic Info & Address
  {
    fields: [
      { 
        type: "html", 
        html: `<h2>${trx.organizations["Company Information"]}</h2><p>${trx.organizations["Please fill out your basic company details."]}</p>` 
      },
      {
        type: "input",
        inputType: "text",
        label: (trx.organizations && trx.organizations["Organization name"]) || "Organization Name *",
        model: "name",
        required: true,
      },
      {
        type: "input",
        inputType: "url",
        label: trx.organizations["Website URL"] || "Website URL",
        model: "website",
        required: false,
        placeholder: "https://yourstore.com",
        validator: VueFormGenerator.validators.url,
      },
      {
        type: "select",
        label: trx.organizations["Industry"] || "Industry",
        model: "industry",
        selectOptions: {
          noneSelectedText: trx.organizations["Nothing selected"] || "Nothing selected"
        },
        required: true,
        values: trx.organizations.Industries, // Uses the Industries array from trx
        valueFormat: "id"
      },
      {
        type: "input",
        inputType: "text",
        label: (trx.organizations && trx.organizations["vat_number"]) || "VAT Number",
        model: "vat_id",
        required: true,
        onChanged: (_, newVal) => this.fetchCompanyInfo(newVal),
      },
      {
        type: "input",
        inputType: "text",
        label: (trx.organizations && trx.organizations["coc_number"]) || "Chamber of Commerce Number",
        model: "coc_number",
        required: true,
      },
      {
        type: "input",
        inputType: "file",
        label: trx.organizations["Company Logo"] || "Company Logo (PNG, JPG, SVG)",
        model: "temp_company_logo",
        attributes: { accept: "image/png, image/jpeg, image/svg+xml" },
      },
      {
        type: "input",
        inputType: "file",
        label: trx.organizations["Upload VAT Exemption"] || "Upload VAT Exemption (PDF)",
        model: "vat_exemption_doc",
        attributes: { accept: "application/pdf" },
      },
    ],
  },

  // STEP 2: Contacts & File Upload
  {
    fields: [
      { 
        type: "html", 
        html: `<h2>${trx.organizations["Contact Information"]}</h2><p>${trx.organizations["Provide the details of your main contact person."]}</p>` 
      },
      {
        type: "array",
        model: "contacts",
        label: trx.organizations["Contacts"] || "Contacts",
        showRemoveButton: true,
        fieldClasses: "arrayEditor",
        newElementButtonLabelClasses: "button primary",
        items: {
          type: "object",
          default: {
            name: "John Doe",
            email: "info@example.com",
            contact_number: "+31640639208",
            role: trx.organizations["General"] || "General",
          },
          schema: {
            fields: [
              {
                type: "input",
                inputType: "text",
                label: trx.organizations["Full Name"] || "Full Name",
                model: "name",
                required: true,
              },
              {
                type: "input",
                inputType: "email",
                label: trx.organizations["Email"] || "Email",
                model: "email",
                required: true,
              },
              {
                type: "phone",
                label: trx.organizations["Phone"] || "Phone",
                model: "contact_number",
                required: true,
                defaultCountry: "nl",
                preferredCountries: ["nl", "be", "de"],
              },
              {
                type: "select",
                label: trx.organizations["Role"] || "Role",
                model: "role",
                values: [
                  trx.organizations["General"] || "General",
                  trx.organizations["Finance"] || "Finance",
                  trx.organizations["Technical"] || "Technical",
                  trx.organizations["Support"] || "Support",
                ],
                required: true,
              },
              {
                type: "checkbox",
                label: trx.organizations["Invite for user account"] || "Invite for user account",
                model: "invite_user",
              },
            ],
          },
        },
      },
    ],
  },

  // STEP 3: Shipping & Fulfillment
  {
    fields: [
      {
        type: "html",
        html: `<div class="info-section">
          <h2>${trx.organizations["Shipping & Fulfillment"] || "Shipping & Fulfillment"}</h2>
          <p>${trx.organizations["We ensure fast and reliable shipping to get your products to customers efficiently..."] || "We ensure <strong>fast and reliable shipping</strong> to get your products to customers efficiently..."}</p>
        </div>`,
      },
      {
        type: "select",
        label: trx.organizations["Number of SKUs"] || "Number of SKUs",
        model: "sku_count",
        required: true,
        selectOptions: { noneSelectedText: trx.organizations["Nothing selected"] || "Nothing selected" },
        values: trx.organizations.SKUs, // Uses the SKUs array from trx
        valueFormat: "id",
      },
      {
        type: "select",
        label: trx.organizations["Annual Order Volume"] || "Annual Order Volume",
        model: "annual_orders",
        required: true,
        selectOptions: { noneSelectedText: trx.organizations["Nothing selected"] || "Nothing selected" },
        values: trx.organizations.OrderVolume, // Uses the OrderVolume array from trx
        valueFormat: "id",
      },
      {
        type: "select",
        label: trx.organizations["Shipping Origin Country"] || "Shipping Origin Country",
        model: "country",
        required: true,
        selectOptions: { noneSelectedText: trx.organizations["Nothing selected"] || "Nothing selected" },
        values: trx.organizations.Countries, // Uses the Countries array from trx
        valueFormat: "id",
      },
      {
        type: "select",
        label: trx.organizations["Preferred Shipping Carrier"] || "Preferred Shipping Carrier",
        model: "preferred_shipping_carrier",
        required: false,
        selectOptions: { noneSelectedText: trx.organizations["Nothing selected"] || "Nothing selected" },
        values: trx.organizations.Carriers, // Uses the Carriers array from trx
        valueFormat: "id",
      },
      {
        type: "input",
        inputType: "text",
        label: trx.organizations["Do you have any special packaging requirements?"] || "Do you have any special packaging requirements?",
        model: "special_packaging",
        required: false,
        placeholder: trx.organizations["Fragile items, heavy-duty, branded packaging, etc."] || "Fragile items, heavy-duty, branded packaging, etc.",
      },
    ],
  },

  // STEP 4: Storage & Warehousing
  {
    fields: [
      {
        type: "html",
        html: `<div class="info-section">
          <h2>${trx.organizations["Storage & Warehousing"] || "Storage & Warehousing"}</h2>
          <p>${trx.organizations["With secure and scalable storage solutions, we help businesses manage inventory efficiently. Our warehouses support long-term storage, bulk stock management, and fulfillment centers."] 
              || "With <strong>secure and scalable storage solutions</strong>, we help businesses manage inventory efficiently. Our warehouses support <em>long-term storage, bulk stock management, and fulfillment centers</em>."}</p>
        </div>`,
      },
      {
        type: "input",
        inputType: "number",
        label: trx.organizations["How many pallets do you store on average?"] || "How many pallets do you store on average?",
        model: "pallets_in_storage",
        required: false,
        placeholder: "50",
      },
      {
        type: "select",
        label: trx.organizations["Storage Duration"] || "Storage Duration",
        model: "storage_duration",
        required: false,
        selectOptions: { noneSelectedText: trx.organizations["Nothing selected"] || "Nothing selected" },
        values: trx.organizations.StorageDuration, // Uses StorageDuration array from trx
        valueFormat: "id",
      },
      {
        type: "select",
        label: trx.organizations["How often do you rotate/change pallets?"] || "How often do you rotate/change pallets?",
        model: "pallet_change_frequency",
        required: false,
        selectOptions: { noneSelectedText: trx.organizations["Nothing selected"] || "Nothing selected" },
        values: trx.organizations.PalletRotation, // Uses PalletRotation array from trx
        valueFormat: "id",
      },
    ],
  },

  // STEP 5: Returns & Reverse Logistics
  {
    fields: [
      {
        type: "html",
        html: `<h2>${trx.organizations["Returns"] || "Returns"}</h2>
               <p>${trx.organizations["Define your return handling policies to ensure smooth processing."] || "Define your return handling policies to ensure smooth processing."}</p>`
      },
      {
        type: "select",
        label: trx.organizations["Do you accept returns? And should we handle them?"] || "Do you accept returns? And should we handle them?",
        model: "returns_policy",
        required: true,
        selectOptions: { noneSelectedText: trx.organizations["Nothing selected"] || "Nothing selected" },
        values: [
          { id: true, name: trx.organizations["Yes"] || "Yes" },
          { id: false, name: trx.organizations["No"] || "No" }
        ],
        valueFormat: "id",
      },
      {
        type: "select",
        label: trx.organizations["How should we handle your defect returns?"] || "How should we handle your defect returns?",
        model: "handel_defect_returns",
        required: true,
        selectOptions: { noneSelectedText: trx.organizations["Nothing selected"] || "Nothing selected" },
        values: [
          { id: "keep", name: trx.organizations["Keep the returns on a pallet location"] || "Keep the returns on a pallet location" },
          { id: "destroy", name: trx.organizations["Destroy them"] || "Destroy them" }
        ],
        valueFormat: "id",
        visible(model) { return model.returns_policy; }
      },
      {
        type: "select",
        label: trx.organizations["How should we handle your sellable returns?"] || "How should we handle your sellable returns?",
        model: "handel_sellable_returns",
        required: true,
        selectOptions: { noneSelectedText: trx.organizations["Nothing selected"] || "Nothing selected" },
        values: [
          { id: "inbound_same", name: trx.organizations["Inbound them to same location as normal products"] || "Inbound them to same location as normal products" },
          { id: "inbound_other", name: trx.organizations["Inbound them to other location as return deal"] || "Inbound them to other location as return deal" },
          { id: "keep_different", name: trx.organizations["Keep the return on a different pallet location"] || "Keep the return on a different pallet location" },
          { id: "destroy", name: trx.organizations["Destroy them"] || "Destroy them" }
        ],
        valueFormat: "id",
        visible(model) { return model.returns_policy; }
      },
      {
        type: "checkbox",
        label: trx.organizations["Do you require original packaging for sellable returns?"] || "Do you require original packaging for sellable returns?",
        model: "require_original_packaging",
        visible(model) { return model.returns_policy; }
      },
      {
        type: "select",
        label: trx.organizations["Return Window (How many days after purchase can customers return items?)"] || "Return Window (How many days after purchase can customers return items?)",
        model: "return_window",
        required: true,
        selectOptions: { noneSelectedText: trx.organizations["Nothing selected"] || "Nothing selected" },
        values: [
          { id: "7", name: trx.organizations["7 days"] || "7 days" },
          { id: "14", name: trx.organizations["14 days"] || "14 days" },
          { id: "30", name: trx.organizations["30 days"] || "30 days" },
          { id: "60", name: trx.organizations["60 days"] || "60 days" },
          { id: "90", name: trx.organizations["90 days"] || "90 days" },
          { id: "custom", name: trx.organizations["Custom"] || "Custom" }
        ],
        valueFormat: "id",
        visible(model) { return model.returns_policy; }
      },
      {
        type: "select",
        label: trx.organizations["Who covers return shipping costs?"] || "Who covers return shipping costs?",
        model: "return_shipping_cost",
        required: true,
        selectOptions: { noneSelectedText: trx.organizations["Nothing selected"] || "Nothing selected" },
        values: [
          { id: "customer", name: trx.organizations["Customer"] || "Customer" },
          { id: "business", name: trx.organizations["Business covers it"] || "Business covers it" },
          { id: "depends", name: trx.organizations["Depends on reason"] || "Depends on reason" }
        ],
        valueFormat: "id",
        visible(model) { return model.returns_policy; }
      },
      {
        type: "select",
        label: trx.organizations["How do you process returns?"] || "How do you process returns?",
        model: "return_processing",
        required: true,
        selectOptions: { noneSelectedText: trx.organizations["Nothing selected"] || "Nothing selected" },
        values: [
          { id: "refund", name: trx.organizations["Refund"] || "Refund" },
          { id: "store_credit", name: trx.organizations["Store Credit"] || "Store Credit" },
          { id: "exchange", name: trx.organizations["Exchange"] || "Exchange" }
        ],
        valueFormat: "id",
        visible(model) { return model.returns_policy; }
      },
      {
        type: "input",
        inputType: "text",
        label: trx.organizations["What are the conditions for accepting a return?"] || "What are the conditions for accepting a return?",
        model: "return_conditions",
        required: false,
        placeholder: trx.organizations["E.g., unused items, original packaging, return within 30 days..."] || "E.g., unused items, original packaging, return within 30 days...",
        visible(model) { return model.returns_policy; }
      },
      {
        type: "select",
        label: trx.organizations["Estimated Processing Time for Returns"] || "Estimated Processing Time for Returns",
        model: "return_processing_time",
        required: true,
        selectOptions: { noneSelectedText: trx.organizations["Nothing selected"] || "Nothing selected" },
        values: [
          { id: "same_day", name: trx.organizations["Same day"] || "Same day" },
          { id: "1_3", name: trx.organizations["1-3 business days"] || "1-3 business days" },
          { id: "3_5", name: trx.organizations["3-5 business days"] || "3-5 business days" },
          { id: "7_10", name: trx.organizations["7-10 business days"] || "7-10 business days" }
        ],
        valueFormat: "id",
        visible(model) { return model.returns_policy; }
      },
      {
        type: "input",
        inputType: "email",
        label: trx.organizations["Support Email for Return Inquiries"] || "Support Email for Return Inquiries",
        model: "return_support_email",
        required: true,
        placeholder: trx.organizations["support@yourcompany.com"] || "support@yourcompany.com",
        validator: VueFormGenerator.validators.email,
        visible(model) { return model.returns_policy; }
      },
      {
        type: "input",
        inputType: "text",
        label: trx.organizations["Phone Number for Return Support"] || "Phone Number for Return Support",
        model: "return_support_phone",
        required: false,
        placeholder: trx.organizations["+31 85 0609 250"] || "+31 85 0609 250",
        visible(model) { return model.returns_policy; }
      },
    ],
  },

  // STEP 6: Growth Path
  {
    fields: [
      {
        type: "html",
        html: `<h2>${trx.organizations["Growth path"] || "Growth path"}</h2>
               <p>${trx.organizations["Tell us more about your ambitions."] || "Tell us more about your ambitions."}</p>`
      },
      {
        type: "select",
        label: trx.organizations["Expected Order Growth in the Next 12 Months"] || "Expected Order Growth in the Next 12 Months",
        model: "expected_order_growth",
        required: false,
        selectOptions: { noneSelectedText: trx.organizations["Nothing selected"] || "Nothing selected" },
        values: trx.organizations.GrowthPath, // Uses the GrowthPath array from trx
        valueFormat: "id",
      },
      {
        type: "input",
        inputType: "textarea",
        label: trx.organizations["Do you have any expansion plans?"] || "Do you have any expansion plans?",
        model: "expansion_plans",
        required: false,
        placeholder: trx.organizations["e.g., expanding to new countries, launching new products"] || "e.g., expanding to new countries, launching new products"
      },
    ],
  },

  // STEP 7: Address & Billing
  {
    fields: [
      {
        type: "html",
        html: `<h2>${trx.organizations["Address & Billing"] || "Address & Billing"}</h2>
               <p>${trx.organizations["Company address"] || "Company address"}</p>`
      },
      {
        type: "input",
        inputType: "text",
        label: (trx.organizations && trx.organizations["Postal Code"]) || "Postal Code",
        model: "address.postalCode",
        required: true,
      },
      {
        type: "input",
        inputType: "text",
        label: (trx.organizations && trx.organizations["House Number"]) || "House Number",
        model: "address.houseNumber",
        required: true,
      },
      {
        type: "input",
        inputType: "text",
        label: (trx.organizations && trx.organizations["Street"]) || "Street",
        model: "address.street",
        required: true,
      },
      {
        type: "input",
        inputType: "text",
        label: (trx.organizations && trx.organizations["City"]) || "City",
        model: "address.city",
        required: true,
      },
      {
        type: "select",
        inputType: "text",
        label: (trx.organizations && trx.organizations["Country"]) || "Country",
        model: "address.country",
        required: true,
        selectOptions: { noneSelectedText: trx.organizations["Nothing selected"] || "Nothing selected" },
        values: trx.organizations.Countries, // Uses Countries array from trx
        valueFormat: "id",
        default: "NL",
      },
      {
        type: "input",
        inputType: "text",
        label: (trx.organizations && trx.organizations["Email"]) || "Email",
        model: "address.email",
        required: true,
      },
      {
        type: "checkbox",
        label: trx.organizations.notSameBilling_email,
        model: "notSameBilling_email",
      },
      {
        type: "input",
        inputType: "text",
        label: (trx.organizations && trx.organizations["Email"]) || "Email",
        model: "address.billing_email",
        required: true,
        visible(model) { return model.notSameBilling_email; },
      },
      {
        type: "checkbox",
        label: trx.organizations.notSameBilling,
        model: "notSameBilling",
      },
      {
        type: "html",
        html: `<p>${trx.organizations["Billing address"] || "Billing address"}</p>`,
        visible(model) { return model.notSameBilling; },
      },
      {
        type: "input",
        inputType: "text",
        label: (trx.organizations && trx.organizations["Postal Code"]) || "Postal Code",
        model: "billingAddress.postalCode",
        fieldClasses: "flex-50",
        required: true,
        visible(model) { return model.notSameBilling; },
      },
      {
        type: "input",
        inputType: "text",
        label: (trx.organizations && trx.organizations["House Number"]) || "House Number",
        model: "billingAddress.houseNumber",
        fieldClasses: "flex-50",
        required: true,
        visible(model) { return model.notSameBilling; },
      },
      {
        type: "input",
        inputType: "text",
        label: (trx.organizations && trx.organizations["Street"]) || "Street",
        model: "billingAddress.street",
        required: true,
        visible(model) { return model.notSameBilling; },
      },
      {
        type: "input",
        inputType: "text",
        label: (trx.organizations && trx.organizations["City"]) || "City",
        model: "billingAddress.city",
        required: true,
        visible(model) { return model.notSameBilling; },
      },
    ],
  },
]
    }
  },

  computed: {
    // Show only the partial fields for the current step
    activeSchema() {
      return {
        fields: this.wizardSteps[this.currentStep].fields
      }
    },
    

  },
  methods: {
    // Step navigation
    nextStep() {
      // Example if you want to validate each step:
      // this.$refs.vfgForm.validate().then(isValid => {
      //   if (isValid && this.currentStep < this.wizardSteps.length - 1) {
      //     this.currentStep++
      //   }
      // })

      if (this.currentStep < this.wizardSteps.length - 1) {
        this.currentStep++
      }
    },

    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep--
      }
    },

    onSubmitForm() {
      console.log('Form Submitted, all steps done!', this.FormModel)
      this.submit(this.FormModel);
    },

    // Called when VAT changes
    async fetchCompanyInfo(vatValue) {
      if (!vatValue) return
      try {
        const response = await axios.get(
          `https://ec.europa.eu/taxation_customs/vies/vatResponse.html?vatNumber=${vatValue}`
        )
        if (response.data) {
          // Example: set the org name from the returned data
          this.FormModel.name = response.data.company_name || this.FormModel.name
        }
      } catch (error) {
        console.error('VAT lookup failed:', error)
      }
    },
    copyBillingAddress() {
      if (this.FormModel.sameBilling) {
        const { street, houseNumber, postalCode, city } = this.FormModel.address
        this.FormModel.billing_address = `${street} ${houseNumber}, ${postalCode} ${city}`.trim()
      }
    },

    async handleFileChange(event) {
      const file = event.target.files[0]
      if (file) {
        try {
          const base64String = await this.convertFileToBase64(file)
          this.FormModel.company_logo = {
            img_data: base64String,
            img_ext: file.type.split('/')[1]
          }
        } catch (error) {
          console.error('Error converting file to Base64:', error)
        }
      }
    },

    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          resolve(reader.result.split(',')[1])
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    }
  },

  mounted() {
    // Attach file change listeners
    const fileInputs = this.$el.querySelectorAll('input[type="file"]')
    fileInputs.forEach((input) => {
      input.addEventListener('change', this.handleFileChange)
    })
  },

  beforeDestroy() {
    // Remove file change listeners
    const fileInputs = this.$el.querySelectorAll('input[type="file"]')
    fileInputs.forEach((input) => {
      input.removeEventListener('change', this.handleFileChange)
    })
  }
}
</script>

<style lang="scss" scoped >
.wizard-container {
  margin: auto;
  .vue-form-generator {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
    height: calc(100vh - 40vh);
  }
  background: var(--color-white);
  padding: 2rem;
  border-radius: 10px;
 
}

/* Minimal step indicator styling */
.steps-indicator {
  text-align: center;
  margin-bottom: 2rem;
}
.step-dot {
  @include box-bg-solid;
  display: inline-block;
  padding: 2rem 2rem;
  line-height: 24px;
  border-radius: 50%;
  background: var(--color-grey-light-1);
  margin: 0 2rem;
  text-align: center;
  color: var(--color-black);
  position: relative;
  z-index: 2;
 
  &:not(:last-child)::after {
    content: '';
    display: block;
    width: 5rem;
    height: 5px;
    background: var(--color-primary);
    margin: 0 auto;
    position: absolute;
    top:2.8rem;
    z-index: 1;
    right: -5rem;
  }
  &.active {
    background: var(--color-primary);
    color: var(--color-white);
  }
}

.wizard-nav {
  margin-top: 1rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}
.wizard-nav button {
  
}
</style>