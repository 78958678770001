<template>
    <div class="delivery-container flex">

        <div class="o_det flex flex-70 flex-70-md">
            <p class="platform"><strong>{{ node.platform }}</strong> {{ node.orderId }}  <a v-if="node.batch_id" target="_blank" rel="noopener noreferrer" :href="`/api/test/batch/${node.batch_id}`">BATCH</a> </p>
            <p class="flex-100" v-if="node.shipmentDetails"> <span class="muted">{{ trx.shipments.orderd_by }}</span> <br> {{node.shipmentDetails ? node.shipmentDetails.companyName : ''}} {{ node.shipmentDetails ? node.shipmentDetails.firstName : ''}} {{ node.shipmentDetails ? node.shipmentDetails.surname : ''}} </p>
            <div v-if="node.fulfilledDateTime" class="o_det__shipmt flex flex-100 flex-100-md">
                <p class="flex-30 flex-50-md"> <span class="muted">{{trx.shipments.order_date}}</span> <br> {{ moment(node.orderPlacedDateTime).format(trx.date_format) }} <br>Om {{ moment(node.orderPlacedDateTime).format(trx.time_format) }}</p>
                <p v-if="node.fulfilledDateTime" class="flex-30 flex-50-md"> <span class="muted">{{trx.shipments.sent_date}}</span> <br>{{ moment(node.fulfilledDateTime).format(trx.date_format) }} <br> Om {{ moment(node.fulfilledDateTime).format(trx.time_format) }}</p>
                <p v-if="node.deliveryDateTime" class="flex-30 flex-50-md"> <span class="muted">{{ trx.shipments.delivery_date }}</span> <br>{{ moment(node.deliveryDateTime).format(trx.date_format) }} <br> Om {{ moment(node.deliveryDateTime).format(trx.time_format) }}</p>
            </div>
            
            <p class="flex-100" v-if="node.track_trace"> 
                <span class="muted">{{ trx.shipments.shipment_method }}</span> <br> 
                {{node.used_shipment_method && node.used_shipment_method.length ? node.used_shipment_method[0].name : ''}} 
                <br />  <a target="blank" :href="'https://jouw.postnl.nl/track-and-trace/'+node.track_trace+'-'+node.shipmentDetails.countryCode+'-'+ (node.shipmentDetails.zipCode)">{{node.track_trace}}</a> 
                <br />  <span v-if="node.latest_status"> {{node.latest_status.Description}}</span>  <span v-if="node.latest_status"> {{moment(Date(node.latest_status.TimeStamp)).format(trx.date_format)}}</span>
            </p>
            
        </div>
        <div class="sh_products flex flex-30 flex-30-md">
            <div v-if="node.products.length > 0">
                <div v-for="(product, index) in node.products" :key="index" class="sh_product flex-20">
                    <OrderImage v-bind:key="product.product_id" :node="product"/>
                </div>
            </div>
            <a :href="'/shipments/'+node._id" class="arrow">&gt;</a>
        </div>
        <div class="flex-100">
            <p></p>
        </div>
    </div>
  </template>
  
  <script>

import moment from 'moment';
import trx from '@/translations';
import OrderImage from './order_image.vue'

  export default {
    name: "OrderItem",
    components: {
        OrderImage
    },
    props: {
        node: { 
            type: Object, 
        }
    },
    data() {
      return {
        moment,
        trx
      }
    }
  }

  </script>
  
  <style lang="scss" scoped>

    h2 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .muted {
        color: var(--color-grey-light-4);
        font-size: 1rem;
    }

    .platform {
        position: absolute;
        padding: 0.8rem 2rem !important;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        left:0rem;
        top:-10px;
        background-color: var(--color-white);
    }

    .delivery-container  {
        position: relative;
        @include box-bg-white;
        margin-bottom: 1rem;
        margin-top: 3rem;
    }

    .count {
        font-size: 48px;
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .o_det {
        background-color: #FFFFFF;
        padding: 10px;
        flex-wrap: wrap;
        margin-bottom: 10px;
        border-radius: 5px;
        align-content: flex-start;
        align-items: flex-start;
        font-size: 1rem;
        &__shipmt {
            
        }
        p {
            margin: 0;
            min-width: 90px;
        }

    }

    .sh_products {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        min-height: 100px;
    }

    .sh_product {
        position: relative;
        margin-right: 20px;
    }

    .arrow {
        font-size: 24px;
        position: absolute;
        right: 0;
        height: 100%;
        top: 0;
        background: var(--color-grey-light-3);
        padding: 1rem;
        display: flex;
        align-items: center;
        border-top-right-radius: var(--border-radius-box);
        border-bottom-right-radius: var(--border-radius-box);
        &:hover {
            background: var(--color-primary);
            color: var(--color-white);
            cursor: pointer;
        }

    }
  </style>