import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
/* eslint-disable no-debugger */

// General
import Home from "@/views/Home";

// Orders
import Orders from "@/views/Orders";
import Shipments from "@/views/Shipments/index.vue";
import OrderDetail from "@/views/Shipments/order_item_detail.vue";
import OrderEdit from "@/views/Orders/edit_order.vue";
import newOrder from "@/views/Orders/new_order/index";
import pick_list from "@/views/Orders/pick";
import orders_import from "@/views/Orders/orders_import.vue";

// Return
import Returns from "@/views/Returns";

// Auth
import Signup from "../views/Framework/Auth/Signup.vue";
import Login from "../views/Framework/Auth/Login.vue";
import Reset from "../views/Framework/Auth/Reset.vue";
import Layout from "../views";

// Products
import Products from "@/views/Products/index";
import AddInventory from "@/views/Products/add_inventory";
import PreNotify from "@/views/Products/pre_notify";
import CheckinItemDetails from "@/views/Products/Check_in/checkin_item_details.vue";
import CheckinInventory from "@/views/Products/Check_in";
import InventoryTicket from "@/views/Products/InventoryTicket";
import Checkin from "@/views/Checkin/index";
import Import from "@/views/Products/Import";
import NewProduct from "@/views/Products/new_product"
import InboundDetails from "@/views/Products/Check_in/inbound_details.vue"
import InboundItemScanQR from '@/views/Products/Check_in/searchInboundItem.vue'

// scanner
import Scanner from "@/views/Scanner";

// Planning
import Planning from "@/views/Planning";

// Users
import Users from "@/views/Framework/Users";
import User from "@/views/Framework/Users/user.vue";
import addUser from "@/views/Framework/Users/form.vue";
import ImportUser from "@/views/Framework/Users/import.vue";

// Organizations
import Orgs from "@/views/Framework/Orgs";
import newOrg from "@/views/Framework/Orgs/add";

// Subscriptions
import subscriptions from "@/views/Framework/Subscriptions/";
import addSubscription from "@/views/Framework/Subscriptions/add";
import Terms from "@/views/Framework/Subscriptions/terms";

// Settings
import Settings from "../views/Framework/Settings";
import OrgSettings from "../views/Framework/Settings/org.vue";

import Apis from "@/views/Framework/Apis";
import add_apis from "@/views/Framework/Apis/form.vue";

// Admin
import Admin from "@/views/Admin/index.vue";
import Locations from "@/views/Admin/locations.vue";
import Shipment_box from "@/views/Admin/ship_box";
import add_shipment_box from "@/views/Admin/ship_box/form";
import searchOrderId from "@/views/Admin/SearchOrderId.vue";

import shipment_method from "@/views/Admin/shipment_method";
import add_shipment_method from "@/views/Admin/shipment_method/form";
import surcharge from "@/views/Admin/surcharge";
import add_surcharge from "@/views/Admin/surcharge/form";
import labels from "@/views/Admin/labels";
import transfers from "@/views/Admin/transfers";
import inboundReport from "@/views/Products/Check_in/reporting.vue";
import Sales from "@/views/Sales";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "App",
    component: Layout,
    children: [
      {
        path: "/orgs",
        name: "Organizations",
        component: Orgs,
        children: [
          {
            path: "add",
            name: "add_organization",
            component: newOrg,
          },
        ],
      },
      {
        path: "/settings",
        name: "settings",
        component: Settings,
        children: [
          {
            path: "org",
            name: "org",
            component: OrgSettings,
          },
          {
            path: "users",
            name: "users",
            component: Users,
            children: [
              {
                path: "add",
                component: addUser,
              },
              {
                path: "import",
                component: ImportUser,
              },
            ],
          },
          {
            path: "User",
            name: "User",
            component: User,
          },
          {
            path: "subscription",
            name: "subscription",
            component: subscriptions,
          },
          {
            path: "payments",
            name: "payments",
            component: Orgs,
          },
          {
            path: "org_settings",
            name: "org_settings",
            component: Orgs,
          },
          {
            path: "apis",
            name: "apis",
            component: Apis,
            children: [
              {
                path: "add",
                component: add_apis,
              }
            ],
          },
        ],
      },
      {
        path: "/subscriptions/add",
        name: "Subscriptions",
        component: addSubscription,
      },
      {
        path: "/subscriptions/terms",
        name: "terms",
        component: Terms,
      },
      {
        path: "/Home",
        name: "Home",
        component: Home,
      },
      {
        path: "/Orders",
        name: "Orders",
        component: Orders,
      },
      {
        path: "/orders/import",
        name: "orders_import",
        component: orders_import,
      },
      {
        path: "/order/edit/:id",
        name: "Order_edit",
        component: OrderEdit,
      },
      {
        path: "/new_order",
        name: "newOrder",
        component: newOrder,
      },
      {
        path: "/returns",
        name: "Returns",
        component: Returns,
      },
      {
        path: "/shipments",
        name: "Shipments",
        component: Shipments,
      },
      {
        path: "/shipments/:id",
        name: "Order_detail",
        component: OrderDetail,
      },
      {
        path: "/pick",
        name: "pick",
        component: pick_list,
      },
      {
        path: "/scanner",
        name: "Scanner",
        component: Scanner,
      },
      {
        path: "/checkin",
        name: "Checkin",
        component: Checkin,
      },
      {
        path: "/inbound_details/:id",
        name: "InboundDetails",
        component: InboundDetails,
      },
      {
        path: "/inbound/item",
        name: "InboundItemScanQR",
        component: InboundItemScanQR,
        children: [{
          path: ":id",
          name: "InboundItem",
          component: InboundDetails,
        }],
      },
      {
        path: "/products/import",
        name: "Import",
        component: Import,
      },
      {
        path: "/products/new_product",
        name: "NewProduct",
        component: NewProduct,
      },
      {
        path: "/pre_notify",
        name: "PreNotify",
        component: PreNotify,
      },
      {
        path: "/overview_checkin/:id",
        name: "CheckinItem",
        component: CheckinItemDetails,
      },
      {
        path: "/products",
        name: "Products",
        component: Products,
        children: [
          {
            path: "inventory_ticket",
            name: "InventoryTicket",
            component: InventoryTicket,
          },
        ],
      },
      {
        path: "checkin_inventory",
        name: "CheckInInventory",
        component: CheckinInventory,
      },
      {
        path: "add_inventory",
        name: "AddInventory",
        component: AddInventory,
      },
      {
        path: "admin",
        name: "Admin",
        component: Admin,
        children: [
          // {
          //   path: "search_order",
          //   name: "search_order",
          //   component: searchOrderId,
          // },
          {
            path: "shipment_box",
            name: "shipment_box",
            component: Shipment_box,
            children: [
              {
                path: "add",
                name: "add_shipment_box",
                component: add_shipment_box,
              },
            ],
          },
          {
            path: "shipment_method",
            name: "shipment_method",
            component: shipment_method,
            children: [
              {
                path: "add",
                name: "add_shipment_method",
                component: add_shipment_method,
              },
            ],
          },
          {
            path: "surcharge",
            name: "surcharge",
            component: surcharge,
            children: [
              {
                path: "add",
                name: "add_surcharge",
                component: add_surcharge,
              },
            ],
          },
          {
            path: "locations",
            name: "locations",
            component: Locations,
          },
          {
            path: "labels",
            name: "labels",
            component: labels,
          },
          {
            path: "search_order",
            name: "search_order",
            component: searchOrderId,
          },
          {
            path: "transfers",
            name: "transfers",
            component: transfers,
          }
        ],
      },
      {
        path: "/planning",
        name: "Planning",
        component: Planning,
      },
      {
        path: "/sales",
        name: "Sales",
        component: Sales,
      },
    ],
    meta: {
      rqAuth: true,
    },
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: {
      rqAuth: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      rqAuth: false,
    },
  },
  {
    path: "/inbounds-report",
    name: "inboundReport",
    component: inboundReport,
    meta: {
      rqAuth: false,
    },
  },
  {
    path: "/reset",
    name: "Reset",
    component: Reset,
    meta: {
      rqAuth: false,
    },
  },

  // {
  //   path: '/orgs',
  //   name: 'Organizations',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Orgs/index.vue')
  // }
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.state.auth.current_user !== null;
  const authPaths = ["/login", "/signup", "/inbounds-report", "/reset"];
  const isAuthRoute = authPaths.includes(to.path);

  debugger;
  // Check if the user is already fetched
  if (!isAuthenticated && !isAuthRoute) {
    try {
      // Namespaced action dispatch
      await store.dispatch("fetchUser");

    } catch (error) {
      console.error('Error fetching user:', error);
      // Optionally handle the error
    }
  }

  // Redirect root path based on authentication
  if (to.path === "/") {
    return isAuthenticated ? next("/home") : next("/login");
  }

  // Fetch user if not on auth-related routes
  if (!isAuthRoute) {
    try {
      await store.dispatch("fetchUser");
    } catch {
      return next("/login");
    }

    if (!store.state.auth.current_user) {
      return next("/login");
    }
  }

  // Routes that require authentication
  if (to.matched.some((record) => record.meta.rqAuth)) {
    if (!store.state.auth.current_user) {
      try {
        await store.dispatch("fetchUser");
      } catch {
        return next("/login");
      }
    }

    await store.dispatch("fetchCurrOrg");

    const currentUser = store.state.auth.current_user;
    const currentOrgAuth = store.state.orgs.current_org_auth;

    if (!currentUser) {
      return next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else if (!currentOrgAuth && to.path !== "/orgs/add") {
      return next({
        path: "/orgs/add",
        params: { nextUrl: to.fullPath },
      });
    } else {
      return next();
    }
  } else {
    // Redirect authenticated users away from public pages
    if (isAuthenticated && isAuthRoute) {
      return next("/home");
    } else {
      return next();
    }
  }
});


export default router;
