<template>
    <div class="users-wrp">
        <h3> </h3>

        <div v-if="order !== (null || undefined)" class="orders">
            <div class="order">
                <div class="box-bg">
                    <h6>{{ trx.orders.order }}</h6>
                    <p>{{ order.orderId }} </p>
                </div>
                <div class="flex  flex-20 order__dates">
                    <div class="">
                        <h6>Geplaatst</h6>
                        {{ moment(order.orderPlacedDateTime).locale("NL").format("DD-MM-YYYY HH:mm") }}
                    </div>
                    <div v-if="order.fulfilledDateTime">
                        <h6>Verwerkt</h6>
                        {{ moment(order.fulfilledDateTime).locale("NL").format("DD-MM-YYYY HH:mm") }}
                    </div>
                    <div v-if="order.deliveryDateTime">
                        <h6>Geleverd</h6>
                        {{ moment(order.deliveryDateTime).locale("NL").format("DD-MM-YYYY HH:mm") }}
                    </div>
                </div>

                <div class="flex-20 order__prgs" v-if="order.shipment_status && order.shipment_status[0]">
                    <h6>Verzendstatus</h6>
                    <div v-if="order.shipment_status && order.shipment_status[0].Event">
                        <div class="order__prgs__item"
                            v-for="sp in order.shipment_status[0].Event.filter((value, index, self) => index === self.findIndex((t) => (t.Code === value.Code)))"
                            v-bind:key="sp._id">

                            <span :class="'ic_code-' + sp.Code + ' order__prgs__item__icon'"
                                :title="sp.Description + ' code:' + sp.Code">
                                <img
                                    :src="'A01,B01,J01,J05,I11,'.includes(sp.Code) ? require('@/assets/icons/delivery_icons/' + sp.Code + '.svg') : require('@/assets/icons/delivery_icons/' + 'A01' + '.svg')">
                            </span>
                            <!-- <span class="order__prgs__item__time">{{moment(sp.TimeStamp, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY')}}</span> -->
                            <!-- <span class="order__prgs__item__desc">{{sp.Description}}</span> -->

                        </div>

                    </div>
                    <a target="blank"
                        :href="'https://jouw.postnl.nl/track-and-trace/' + order.track_trace + '-' + order.shipmentDetails.countryCode + '-' + (order.shipmentDetails.zipCode)">{{
                        order.track_trace }}</a>
                    <br>
                </div>

                <div class="flex-100 box-bg order__prgs" v-else>
                    <h6>Verzendstatus</h6>
                    Niet bekend
                </div>

                <div class="flex-15 box-bg">
                    <h6>Producten in deze zending</h6>
                    <div class="flex order__products">
                        <div v-for="product in order.products" v-bind:key="product._id"
                            class="flex order__products__product">
                            <div class="">
                                <div class="" v-if="product">
                                    <br>
                                    <OrderImage v-bind:key="product._id" :node="product" />
                                    <br>
                                    <h6>{{ product.ean }} X {{ product.quantity }} </h6>
                                </div>
                                <span v-if="product.product_id.locations && product.product_id.locations[0]">{{
                                    product.product_id.locations[0].location_id.parent.name }}-{{
                                        product.product_id.locations[0].location_id.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex-15 box-bg" v-if="order.shipmentDetails">
                    <div class="user__name col-2">{{ trx.orders["Track Trace"] }}</div>
                    <div class="user__name col-2">
                        <div class="order__shipping">
                            <h6>Bestelgegevens</h6>
                            <p>{{ order.shipmentDetails.firstName }} {{ order.shipmentDetails.surname }}</p>
                            <p>{{ order.shipmentDetails.streetName }} {{ order.shipmentDetails.houseNumber }}</p>
                            <p>{{ order.shipmentDetails.houseNumberExtension }}</p>
                            <p>{{ order.shipmentDetails.zipCode }} {{ order.shipmentDetails.city }}</p>
                        </div>
                    </div>
                </div>

                <div class="flex-15 actions flex">
                    <!-- <button v-on:click="restore_order(order.orderId)"> Restore order </button> -->
                    <h6>Acties</h6>
                    <div>
                        <button class="button" @click="printPdf">
                            Download label
                        </button>
                        <button class="button" @click="downloadPackingList({ _id: order._id })">
                            Download pakbon
                        </button>
                        <button v-if="order.attachments" class="button" @click="downloadAttachments">
                            Download bijlagen
                        </button>
                    </div>
                    <iframe ref="pdfFrame" style="display:none"></iframe>
                    <br>
                    <div class="warning_button" v-on:click="recreate_order(order)"> Verstuur opnieuw</div>
                    <br>
                    <div v-if="!order.isFulfilledBySF || order.isFulfilledBySF === false" class="warning_button"
                        v-on:click="edit_order(order)"> Order aanpassen</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import infoBox from '@/components/Layout/InfoBox';
import moment from 'moment';
import trx from '@/translations';
import OrderImage from './order_image.vue'

/* eslint-disable no-debugger */

export default {
    name: 'Shipments',
    computed: {
        ...mapGetters(['order', 'shipment_details', 'order_image', 'order_products', 'attachment_files'])
    },
    methods: {
        ...mapActions(['get_order', 'restore_order', 'resetOrderProducts', 'addOrderProduct', 'resetShipmentDetails', 'get_order_image', 'downloadPackingList', 'get_attachment_files']),
        recreate_order(order) {

            // Set the order_products state in vuex
            this.resetOrderProducts();
            this.resetShipmentDetails();
            order.products.forEach(async (product) => {
                await this.get_order_image(product.product_id._id);
                let p = {};
                p._id = product.product_id._id;
                p.title = product.title;
                p.ean = product.ean;
                p.quantity = product.quantity;
                p.img = 'data:image/' + this.order_image.img_ext + ';base64,' + this.order_image.img_data;

                this.addOrderProduct(p);
            });


            // Set the shipment_details state in vuex
            this.shipment_details.company = order.shipmentDetails.company ? order.shipmentDetails.company : '';
            this.shipment_details.name = order.shipmentDetails.firstName;
            this.shipment_details.surename = order.shipmentDetails.surname;
            this.shipment_details.countryCode = order.shipmentDetails.countryCode;
            this.shipment_details.postalcode = order.shipmentDetails.zipCode;
            this.shipment_details.housenumber = order.shipmentDetails.houseNumber;
            this.shipment_details.houseNumberExtension = order.shipmentDetails.houseNumberExtension;
            this.shipment_details.streetname = order.shipmentDetails.streetName;
            this.shipment_details.city = order.shipmentDetails.city;
            this.shipment_details.email = order.shipmentDetails.email;
            this.shipment_details.phone = order.shipmentDetails.phone ? order.shipmentDetails.phone : '';

            // Check if the order has a housenumber
            this.check_housenumber()

            this.$router.push('/new_order');
        },

        check_housenumber() {
            if (this.shipment_details.name) {
                // Check if the house number is filled in
                if (!this.shipment_details.housenumber && this.shipment_details.streetname) {

                    // Look for numbers in the street name
                    const numbers = this.shipment_details.streetname.match(/\d+/g);
                    if (numbers) {
                        // Get the index of the first number
                        const index = this.shipment_details.streetname.indexOf(numbers[0]);

                        const streetname = this.shipment_details.streetname.substring(0, index);
                        const housenumber = this.shipment_details.streetname.substring(index, index + numbers[0].length);
                        const housenumberExtension = this.shipment_details.streetname.substring(index + numbers[0].length);

                        this.shipment_details.streetname = streetname;
                        this.shipment_details.housenumber = housenumber;
                        this.shipment_details.houseNumberExtension = housenumberExtension;
                    }
                }
            }
        },
        edit_order(order) {
            this.$router.push('/order/edit/' + order._id);
        },
        printPdf() {
            const pdfData = this.order.label;

            const byteCharacters = atob(pdfData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            const url = URL.createObjectURL(blob);
            const iframe = this.$refs.pdfFrame;

            iframe.src = url;

            iframe.onload = () => {
                iframe.contentWindow.print();
            };
        },
        async downloadAttachments() {
            // Get all attachments from the API
            await this.get_attachment_files(this.order._id)
            const files_length = this.attachment_files.length

            if (files_length === 0) {
                alert("Geen bijlagen gevonden")
                return
            }

            let pdfWindow = window.open("", "_blank")
            pdfWindow.document.write("<head><title>Order-" + this.order.orderId + "</title><style>* {font-family: sans-serif;}</style></head><h1 stlyle='font-weight: bold;'>ORDER INSTRUCTIES:</h1><h3>“"
                + this.order.attachments.instructions +
                "”</h3>")
            this.attachment_files.forEach((file, idx) => {
                pdfWindow.document.write(
                    "<h2>Bestand: " + (idx + 1) + "/" + files_length + "</h2><h5>" + encodeURIComponent(file.file_name) + "</h5><iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                    encodeURI(file.file_data) + "'></iframe>"
                )
            });
        },
    },
    components: {
        OrderImage
    },
    created() {
        this.get_order(this.$router.currentRoute.params.id);
    },
    data() {
        return {
            moment,
            trx
        }
    }
}
</script>

<style lang="scss" scoped>
h3 {
    margin: 2rem 0rem;
}

.actions {
    flex-direction: column;
    align-items: flex-start;
    justify-items: flex-start;

    .warning_button,
    a.button {
        min-width: 100% !important;

        text-align: center;
    }
}

.box-bg {
    @include box-bg-white;

}

.orders {
    overflow: auto;
    flex-direction: column;
    max-width: 800px;

    .order {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        margin: 0.5rem 0rem;
        padding: 0.5rem;

        >div {
            overflow: auto;
            margin: 0.5rem 0rem;
        }

        &__dates {
            align-items: flex-start;

            >div {
                margin-right: 1rem;
                @include box-bg-solid;
                padding: 0.5rem;
            }
        }

        &__products {
            justify-content: flex-start;

            &__product {
                margin-right: 2rem;
            }
        }

        &__prgs {
            >div {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;

            }

            &__item {
                margin: 2px;
                color: (--color-white);
                background: var(--color-grey-light-3);
                border-radius: var(--border-radius-box);
                padding: 1rem;

                [class^="ic_code-"] {
                    padding: 1rem;
                }

                &__icon {
                    span {
                        line-height: 0;
                    }

                    img {
                        width: auto;
                        height: 3rem;
                    }
                }

                &:first-child {
                    background: var(--color-primary-light);
                }
            }

        }
    }
}
</style>