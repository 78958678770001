<template>
  <div class="products-wrp">
    <div class="prd_fixed hidden-sm">
    <div class="btn-nav">
      <div class="flex buttons">

        <div class="" >
          <router-link v-if="!$route.path.includes('add')" :to="{ path: '/pre_notify' }" class="button btn-primary i-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <line x1="12" y1="5" x2="12" y2="19"/>
              <line x1="5" y1="12" x2="19" y2="12"/>
            </svg> {{ trx.products["Add inventory"] }}
          </router-link>
        </div>
        <div class="">
          <router-link :to="{ path: '/checkin_inventory' }" class="button i-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M3 3H16V21H3V3Z"/> <!-- Paper/Document -->
              <path d="M8 9H12"/> <!-- First line -->
              <path d="M8 13H12"/> <!-- Second line -->
              <path d="M8 17H12"/> <!-- Third line -->
              <path d="M16 6L21 9L16 12"/> <!-- Arrow pointing into paper -->
            </svg> {{ trx.products["Inventory checkin"] }}
          </router-link>
        </div>
        <div class="">
          <div class="sync_buttons">
            <a v-on:click="drp_open = !drp_open" class="button i-btn color_woo">
              <span v-if="!drp_open">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M12 3V15"/>
                  <path d="M16 11L12 15L8 11"/>
                  <path d="M4 19H20"/>
                </svg>
              </span> 
              <span v-if="drp_open">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <line x1="18" y1="6" x2="6" y2="18"/>
                  <line x1="6" y1="6" x2="18" y2="18"/>
                </svg>
              </span>

              {{ trx.products.sync.import_products }}
              
            </a>

            <div v-if="drp_open" :class="drp_open ? 'controle__btns is-open' : 'controle__btns'">
              <span class="help" :title="trx.products.sync_help">Sync met:</span>
              <button v-on:click="syncProducts({ api_name: 'bol' })" class="button color_bol">
                <img src="https://cdn.brandfetch.io/bol.com/w/25/h/25?c=1idgMT1DczYXT26Zs0h">
                {{ trx.products.sync.bol }}
              </button>
              <button v-on:click="syncProducts({ api_name: 'bol_be' })" class="button color_bol">
                <img src="https://cdn.brandfetch.io/bol.com/w/25/h/25?c=1idgMT1DczYXT26Zs0h">
                {{ trx.products.sync.bol }} BE
              </button>
              <button v-on:click="syncProductsWoo" class="button color_woo">
                <img src="https://cdn.brandfetch.io/woocommerce.com/w/84/h/50/logo?c=1idgMT1DczYXT26Zs0h">
                {{ trx.products.sync.woo }}
              </button>
              <button v-on:click="syncProductsShopify" class="button color_shopify">
                <img src="https://cdn.brandfetch.io/shopify.com/w/50/h/50?c=1idgMT1DczYXT26Zs0h">
                {{ trx.products.sync.shopify }}
              </button>
              <button v-on:click="sync_products_mirakl" class="button color_mirakl">
                <img src="https://cdn.brandfetch.io/mirakl.com/w/52/h/50/theme/light/symbol?c=1idgMT1DczYXT26Zs0h">
                {{ trx.products.sync.mirakl }}
              </button>
              <button v-on:click="syncProductsLightspeed" class="button color_lightspeed">
                <img src="https://cdn.brandfetch.io/lightspeedhq.com/w/50/h/50?c=1idgMT1DczYXT26Zs0h">
                {{ trx.products.sync.lightspeed }}
              </button>
              <button v-on:click="syncProductsShopware" class="button color_shopware">
                <img src="https://cdn.brandfetch.io/shopware.com/w/50/h/50/?c=1idgMT1DczYXT26Zs0h">
                {{ trx.products.sync.shopware }}
              </button>
              <button v-on:click="sync_products_channel_engine" class="button  btn-primary ">
                <img src="https://cdn.brandfetch.io/channelengine.com/w/55/h/50/symbol?c=1idgMT1DczYXT26Zs0h">
                {{ trx.products.sync?.channelengine }}
              </button>
              <router-link to="/products/import" class="button btn-primary color_excel_import">
                <img src="https://cdn.brandfetch.io/microsoft.com/w/50/h/50/symbol?c=1idgMT1DczYXT26Zs0h">
                {{ trx.products.import.xlsx }}
              </router-link>

              <router-link to="/products/new_product" class="button btn-primary color_add_product">
                {{ trx.products.import.add_product }}
              </router-link>

              
            </div>
            
          </div>
        </div>
        <div class="">
          <a class="button i-btn" v-on:click="weekEmail()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M3 5H21V19H3V5Z"/>
              <path d="M3 5L12 13L21 5"/>
            </svg>
            7-day Email
          </a>
        </div>
      </div>
      <div class="flex filter-buttons">
        <Search class="search flex-grow-1 flex-30 flex-100-md flex-100-sm" :search__action="search_products" :searchOnEmpty="true" placeholder="Zoeken..." />
        <button :class="'button i-btn' + (in_stock === true ? ' btn-primary': ' ' )" v-on:click="in_stock = true; getProducts({ limit: limit, skip: skip, in_stock: true });">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <rect x="3" y="3" width="18" height="18" rx="2"/>
            <path d="M3 9H21"/>
            <path d="M9 21V9"/>
          </svg>
          Overzicht
          <span class="badge">{{ total_prod_count.totalProductsInStock }}</span>
        </button>
        <button :class="'button i-btn' + (in_stock === false ? ' btn-primary': ' ' )" v-on:click=" in_stock = false; getProducts({ limit: limit, skip: skip, in_stock: false });">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M3 3H21V21H3V3Z"/>
            <path d="M9 12H15"/>
            <line x1="5" y1="5" x2="19" y2="19"/>
          </svg>
          Uitverkocht
          <span class="badge">{{ total_prod_count.soldOutProducts }}</span>
        </button>
        <button :class="'button i-btn' + (in_stock == null ? ' btn-primary': ' ' )" v-on:click="in_stock = null; getProducts({ limit: limit, skip: skip });">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M3 3H21V21H3V3Z"/>
            <path d="M8 9H16"/>
            <path d="M8 13H16"/>
            <path d="M8 17H16"/>
          </svg>
          Alle producten
          <span class="badge">{{ total_prod_count.totalProducts }}</span>
        </button>
      </div>
      
      
    </div>
    </div>
    <section v-if="$route.path.includes('add') ||
      $route.path.includes('inventory_ticket') ||
      $route.path.includes('checkin_inventory')
      " class="sub backdrop light big">
      <router-view></router-view>
    </section>

    <div v-if="searched_products.length !== 0" class="products">
      <ProductItem v-for="(product, i) in searched_products.filter((x) => {
          return x.parent == null;
      })" v-bind:key="product._id" :product_id="product._id" :product="product" :i="i" :user="user" />
    </div>
    <div v-if="products !== (null || undefined) && searched_products.length === 0" class="products">
      <ProductItem v-for="(product, i) in products.filter((x) => {
        return x.parent == null;
      })" v-bind:key="product._id" :product="product" :product_id="product._id" :i="i" :user="user" />
    </div>

    <div class="flex pagination" v-if="total_prod_count.totalProducts > limit && searched_products.length === 0">
      <button class="flex-5 flex-20-md" v-on:click="previousPage()">ᐸ</button>

      <div class="flex-60 flex-60-md flex">
        <button :class="page == Math.round(skip / limit)
          ? 'active show page_button'
          : page - 2 < Math.round(skip / limit) &&
            page + 3 > Math.round(skip / limit)
            ? 'show page_button flex-5'
            : 'page_button flex-5'
          " v-for="page in Array.from(
    { length: total_prod_count.totalProducts / limit + 3 },
    (x, i) => i
  )" v-bind:key="page" v-on:click="to_page(page)">
          {{ page }}
        </button>
      </div>
      <button class="flex-5 flex-20-md" v-on:click="nextPage()">ᐳ</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProductItem from "./product_item.vue";
import trx from "@/translations";
import Search from "@/components/Search";
import authCheck from "@/lib/authCheck";
import products from "../../store/modules/products";

/* eslint-disable no-debugger */

export default {
  name: "Products",
  computed: {
    ...mapGetters([
      "user",
      "products",
      "current_org",
      "shipment_boxes_list",
      "total_prod_count",
      "searched_products",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchUser",
      "getProducts",
      "search_product_ean",
      "setEmail",
      "getProductLocations",
      "setShareTT",
      "syncProductsWoo",
      "syncProductsShopify",
      "syncProductsLightspeed",
      "syncProductsShopware",
      "sync_products_mirakl",
      "sync_products_channel_engine",
      "setShippingMethod",
      "setProductLocation",
      "setNote",
      "setReturnInstruction",
      "update_min_stock",
      "syncProducts",
      "toggleFulfilment",
      "setShipBox",
      "linkProductLocation",
      "fetch_shipment_boxes_list",
      "fetch_surcharges_list",
      "fetch_shipment_methods_list",
      "fetch_total_products",
      "search_products",
      "weekEmail"
    ]),
    authCheck,
    nextPage() {
      this.skip += this.limit; // For the next page you just increment 'skip' for the page size 'limit'
      this.getProducts({ limit: this.limit, skip: this.skip, in_stock: this.in_stock });
    },
    previousPage() {
      if (this.skip > 0) {
        this.skip -= this.limit; // For the previous page, you just increment 'skip' for the page size 'limit'
        this.getProducts({ limit: this.limit, skip: this.skip, in_stock: this.in_stock });
      }
    },
    to_page(page) {
      this.skip = page * this.limit; // For the next page you just increment 'skip' for the page size 'limit'
      this.getProducts({ limit: this.limit, skip: this.skip, in_stock: this.in_stock });
    },
  },
  components: {
    ProductItem,
    Search,
  },
  created() {
    // const self = this;
    if (!this.user)
      this.fetchUser();
    if (!this,products)
      this.getProducts({ limit: this.limit, skip: this.skip, in_stock: this.in_stock });
    if (!this.total_prod_count)
      this.fetch_total_products();
    if (!this.searched_products)
      this.search_products({ query: "", limit: 10, product_linking: true });
  },
  mounted() {
    
   },
  data() {
    return {
      trx,
      test1: function (x) {
        alert(x);
      },
      drp_open: false,
      limit: 50,
      skip: 0,
      in_stock: true,
      allowDrop: function (ev) {
        ev.preventDefault();
      },
      drag: function (ev) {
        ev.dataTransfer.setData("text", ev.target.id);
      },
      drop: function (ev) {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        ev.target.appendChild(document.getElementById(data));
      },
    };
  },
  watch: {
    searched_products: function () {
      
    },
  },
};
</script>

<style lang="scss">

.products-wrp {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  .action-bar {
    margin-bottom: 5rem;
  }
}

.filter-buttons {
  margin-top: 2rem;
  button {
    border-radius:var(--border-button-radius) ;
    padding: 0.5rem 2rem;
  }
}

.btn-nav {
  .buttons {
    gap:0.5rem;
    button, a {
      margin-right: 1rem;
    }
  }
  a {
    width: auto;
  }
}

.prd_fixed {
  position: fixed;
  width: 85vw;
  padding-top: 5rem;
  top: 0;
  background: var(--color-white);
  border: 1px solid var(--color-primary);
  z-index: 99;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  padding: 2rem;
  .infoBox {
    position: absolute;
    right: 5rem;
    top: -2px;
  }
  @media all and (max-width: $bp-medium) {
    margin-top: 7rem;
    padding-top: 1rem;
  }
  @include super-retina-iphone {
    width: 95vw;
  }
}

h3 {
  margin: 0rem 0rem;
}


.sync_buttons {
  position: relative;
  button,a {
    color: var(--color-white);
    text-align: left;
  }
  
}

.search {
  input {
    width: 100% !important;
    border: 2px solid var(--color-primary);
    height: 4rem !important;
    font-size: 1.7rem;
    margin-bottom: 0.6rem;
    padding: 1rem 1.9rem;
    max-width: 40rem;
  }
}

.controle__btns {
  @include box-bg-dash;
  padding: 2rem;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  position: absolute;
  z-index: 9999;
  top: 5rem;

  transform: translateY(-10px);
  transition: all 0.5s ease-out;

  &.is-open {
    opacity: 1;
    animation: slideDown 0.5s;
    transform: translateY(0);
  }

  .button {
    margin-top: 1rem !important;
    display: flex;
    align-content:center ;
    align-items: center;
    text-align: left;
    img {
      width: 20px;
      height: auto;
      margin-right: 2rem;
    }
  }
}

.product__location__link {
  max-width: 100px;
}

.product__location__minmax {
  position: relative;

  input {
    max-width: 80px;
    height: 50px;
    padding: 20px 10px;
    padding-top: 30px;
  }

  .min span {
    top: 8px;
    left: 10px;
    position: absolute;
  }

  .max span {
    top: 65px;
    left: 10px;
    position: absolute;
  }
}

.button {
  @include button;
  margin-right: 0.2rem;
}

.color_excel_import {
  text-align: center;
  background-color: var(--color-excel);
}

.color_add_product {
  background-color: var(--color-primary-mid);
}

.pagination {
  @include box-bg-white;
  z-index: 999;
  text-align: right;
  align-self: flex-end;
  position: fixed;
  bottom: 0rem;
  right: 10rem;
  button {
    margin-right: 0.5rem;
  }
  @media screen and (max-width: $bp-medium) {
    display: none !important;
    width: 100%;
  }
}

.page_button {
  background-color: var(--color-grey-dark-2);
  visibility: hidden;
  display: none;

  &.active {
    background-color: var(--color-primary);
  }

  &.show {
    visibility: visible;
    display: inline-block;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-10%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

</style>
