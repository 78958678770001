<template>
  <div class="orgs-form">
        <vue-form-generator :schema="schema" :model="FormModel" :options="formOptions"></vue-form-generator>
  </div>
</template>

<script>

import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

import VueFormGenerator from "vue-form-generator";
import trx from '@/translations';
  /* eslint-disable no-debugger */

Vue.use(VueFormGenerator)

export default {
  name: 'organization-form',
  components: {
    "vue-form-generator": VueFormGenerator.component,
  },
  methods: {
    ...mapActions(['store_api', 'get_api', 'update_api'])
  },
  computed: mapGetters(['org_apis']),
  
  data () {
    return {
      trx,
      FormModel: {},
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            class: "dummy_input",
            label: 'usernameField',
            readonly:true,
            autocomplete: 'off',
            model: 'dummy_user',
            required: false,
          },
          {
            type: 'input',
            class: "dummy_input",
            inputType: 'password',
            label: 'passwordField',
            readonly:true,
            autocomplete: 'new-password',
            model: 'dummy_password',
            required: false,
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'API naam *',
            autocomplete: 'off',
            placeholder: 'Praxis NL',
            model: 'model.api_name',
            required: true,
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Platform url',
            autocomplete: 'off',
            placeholder: 'mijnwinkel.myshopify.com',
            model: 'model.url',
            required: false,
          },
          {
            type: 'input',
            inputType: 'text',
            label: 'Key',
            placeholder: '',
            model: 'model.api_key',
            autocomplete: 'none',
            required: false,
          },{
            type: 'input',
            inputType: 'password',
            autocomplete: 'none',
            label: 'Secret *',
            placeholder: '',
            model: 'model.encrypted_secret',
            required: true,
          },{
            type: 'select',
            inputType: 'Select',
            label: 'Platform *',
            placeholder: '',
            model: 'model.platform',
            required: true,
            values: function() {
              return [
                { id: "Bol.com", name: "Bol.com" },
                { id: "Shopify", name: "Shopify" },
                { id: "WooCommerce", name: "WooCommerce" },
                { id: "Mirakl", name: "Mirakl" },
                { id: "Lightspeed", name: "Lightspeed"},
                { id: "Shopware", name: "Shopware"},
                { id: "ChannelEngine", name: "ChannelEngine" },
              ]
            },
            default: "NL",
          
          }, {
            type: 'input',
            inputType: 'text',
            label: 'Platform ID',
            placeholder: '',
            model: 'model.platform_id',
            autocomplete: 'none',
            required: false,
          },{
            type: 'select',
            inputType: 'Select',
            autocomplete:'none',
            label: 'Platform Language',
            placeholder: 'NL,BE,FR,DE,EN',
            model: 'model.platform_lang',
            values: function() {
              return [
                { id: "NL", name: "Netherlands" },
                { id: "EN", name: "English" },
                { id: "DE", name: "German" },
                { id: "IT", name: "Italic" },
                { id: "FR", name: "French" }
              ]
            },
            default: "NL",
            required: false,
          },
          {
            type: 'Submit',
            buttonText: this.submitName,
            onSubmit: () => {
              debugger;
              this.store_api(this.FormModel)
            },
            validateAfterChanged: true,
            validateBeforeSubmit: true
          }
        ]
      },
      formOptions: {
        // validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      }
    }
  }
}
</script>

<style lang="scss"  >

    .readonly  {
      display: none !important;
      visibility: hidden !important;
    }
</style>
