<template>
  <div class="slide-container" >
    <h3 v-for="loc in locations" ref="slides" v-bind:key="loc._id" :class="'mySlides slide ' + (loc?.quantity > 0 ? 'bold' : '') "> {{loc.location_id.parent.name}}-{{loc.location_id.name}}
      <span v-if="loc?.quantity" class="qty">{{ loc?.quantity }} </span>
    </h3>
    
    <span class="dot" v-for="(loc,i) in locations" ref="dots" v-bind:key="i+loc._id"  v-on:click="currentSlide(i)"></span>
      <a class="prev" v-on:click="plusSlides(-1)">&#10094;</a>
      <a class="next" v-on:click="plusSlides(1)">&#10095;</a>
  </div>
</template>

<script>

/* eslint-disable no-debugger */

export default {
  name: 'LocationSlider',
  methods: {
      // Next/previous controls
        plusSlides: function(n) {
            this.showSlides(this.slideIndex += n);
        },
        // Thumbnail image controls
        currentSlide: function(n) {
            this.showSlides(this.slideIndex = n);
        },
        showSlides: function(n) {
            const self = this;
            let i;
            let slides = self.$refs.slides;
            let dots = self.$refs.dots;
            if (n > slides.length) {self.slideIndex = 1}

            if (n < 1) {self.slideIndex = slides.length}

            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" active", "");
            }
            slides[this.slideIndex-1].style.display = "block";
            dots[this.slideIndex-1].className += " active";
        }
  },
  props: {
    locations: Array,
  },
  mounted() {
      if (this.$refs.slides) {
          this.showSlides(this.slideIndex);
      }
  },
  data () {
      return {
        slideIndex: 1,
        
        
      }
  }
}
</script>

<style lang="scss" scoped>

  .slide-container {
      position: relative;
      .next, .prev {
          top: 20%;
          position: absolute;
      }
      .prev {
          left:-1.5rem;
      }
      .next {
          right: -1.5rem;
      }
  }

  h3 {
    position: relative;
    &.bold {
      font-weight: bold;
    }
    span {
      position: absolute;
      padding: 5px 7px;
      border-radius: 50%;
      font-size: 1.5rem;
      color: var(--color-white);
      font-size: 8px;
      top:-2rem;
      left: -2rem;
      background-color: var(--color-purple);
    }
  }

  .mySlides {
      display: none;
  }

  .dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 2px;
    background-color: var(--color-primary);
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }

  .active, .dot:hover {
    background-color: var(--color-purple);
  }

</style>