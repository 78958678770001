<template>
    <div v-html="schema.html"></div>
  </template>
  
  <script>
  import { abstractField } from "vue-form-generator";
  
  export default {
    name: "field-html",
    mixins: [abstractField] // Required for Vue Form Generator fields
  };
  </script>