<template>
  <div class="wrapper">
    <div>
      <h3 class="state-text">{{ this.trx.orders.products_in_order }}</h3>
    </div>
    <div class="container-item">
      <div class="selected-container">
        <SelectedOrder
          v-for="(order_product, idx) in order_products"
          :key="'selected_order_product_' + idx"
          :order_product="order_product"
        />
      </div>
    </div>
    <div class="container-item">
      <h4>{{trx.orders.Receiver}}</h4>
      <div class="recipient-container">
        <div>
          <p>{{ shipment_details.name }} {{ shipment_details.surename }}</p>
          <p>
            {{ shipment_details.streetname }} {{ shipment_details.housenumber
            }}{{ shipment_details.houseNumberExtension }}
          </p>
          <p>{{ shipment_details.postalcode }} {{ shipment_details.city }}</p>
          <p>{{ fullCountryString() }}</p>
        </div>
        <div>
          <p class="email">{{ shipment_details.email }}</p>
          <p>{{ shipment_details.phone }}</p>
        </div>
      </div>
    </div>
    <div class="container-item">
      <h4>{{ this.trx.orders.shipping_method }}</h4>
      <select v-model="shipment_details.shipment_method">
        <option
          class="option-container"
          v-for="(method, idx) in this.shipment_methods_list.filter((filter_method) => {
            if (filter_method.price > 0 || filter_method.code === 'OWN-SHIPMENT') {
              if (filter_method.country_code === shipment_details.countryCode) {
                return filter_method;
              } else if (filter_method.country_code === undefined) {
                return filter_method;
              }
            }
          })"
          :key="'shipment_method_' + idx"
          :value="method.code"
        >
          <h4>{{ method.name }}</h4>
          <p> --- </p>
          <p>€ {{ method.price }}</p>
        </option>
      </select>
    </div>
    <div class="container-item">
      <h4>{{ this.trx.orders.manual_order.reasons.reason }}</h4>
      <select id="type" v-model="shipment_details.type" required>
        <option value="order_not_received">{{this.trx.orders.manual_order.reasons.received}}</option>
        <option value="wrong_product">{{this.trx.orders.manual_order.reasons.incorrect}}</option>
        <option value="wrong_quantity">{{this.trx.orders.manual_order.reasons.quantity}}</option>
        <option value="damaged_product">{{this.trx.orders.manual_order.reasons.damaged}}</option>
        <option value="webshop_order">{{this.trx.orders.manual_order.reasons.webshop}}</option>
      </select>
      <div>
				<h4>{{ trx.shipments.remark }}</h4>
				<textarea class="textarea" v-model="shipment_details.remark"></textarea>
			</div>
    </div>
    <div class="container-item">
      <h4>{{this.trx.orders.attachment}}</h4>
      <div>
        <input v-model="shipment_details.attachment_text" :placeholder="trx.orders.attachment_placeholder" />
        <!-- File input with PDF validation -->
        <input type="file" ref="fileInput" multiple @change="handleFileChange" accept="application/pdf" />
        <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      </div>
    </div>
    <StepController
      :text="trx.orders.to_create"
      :step="3"
      :updateStep="updateStep"
    />
  </div>
</template>
<script>
import trx from '@/translations';
import { mapGetters, mapMutations, mapActions } from "vuex";

import SelectedOrder from "../components/selected_order.vue";
import StepController from "../components/step_controller.vue";

export default {
  name: "OrderConfirmation",
  props: {
    updateStep: Function,
  },
  data() {
    return {
      trx,
      errorMessage:"",
    }
  },
  computed: {
    ...mapGetters([
      "order_products",
      "shipment_details",
      "shipment_methods_list",
      "attachment_files",
    ]),
  },
  methods: {
    ...mapActions(["fetch_shipment_methods_list", "uploadDocuments"]),
    ...mapMutations(['set_attachment_files']),
    fullCountryString() {
      const countryNames = {
        NL: "Netherlands",
        BE: "Belgium",
        DE: "Germany",
        FR: "France",
        GB: "United Kingdom",
        ES: "Spain",
        IT: "Italy",
        PT: "Portugal",
        AT: "Austria",
        DK: "Denmark",
        CH: "Switzerland",
      };

      return (
        countryNames[this.shipment_details.countryCode] ||
        this.shipment_details.countryCode
      );
    },
    handleFileChange(event) {
      this.errorMessage = ""; // Reset error message

      const files = Array.from(event.target.files); // Declare 'files' before using it
      const maxFileSize = 5 * 1024 * 1024; // 5MB limit

      // Validate each file
      for (const file of files) {
        if (file.type !== "application/pdf") {
          this.errorMessage = "Only PDF files are allowed.";
          this.$refs.fileInput.value = ""; // Clear file input
          return;
        }
        if (file.size > maxFileSize) {
          this.errorMessage = `File "${file.name}" exceeds the maximum size of 5MB.`;
          this.$refs.fileInput.value = ""; // Clear file input
          return;
        }
      }

      // If validation passes, store files
      this.shipment_details.files = files;
      this.set_attachment_files(files);
    }
  },
  components: {
    SelectedOrder,
    StepController,
  },
  created() {
    this.fetch_shipment_methods_list();
    this.shipment_details.files = [];
    this.shipment_details.remark = "";
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  padding-bottom: 20rem;
}

.container-item {
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.selected-container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  width: auto;
}

.recipient-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.option-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.email {
  max-width: 30ch;
  word-wrap: break-word;
}

.textarea {
  resize: vertical;
}
</style>
