

<template>
    <div>
      <vue-cal :events="calendarEvents" default-view="week" :hide-view-details="false" :time="false">
        <!-- Customize the day content to display events as a list -->
        !-- Custom title -->
        <template #title="{ title, view }">
          
          <span v-if="view.id === 'years'">Years</span>
          <!-- Using Vue Cal injected Date prototypes -->
          <span v-else-if="view.id === 'year'">{{ view.startDate.format('YYYY') }}</span>
          <span v-else-if="view.id === 'month'">{{ view.startDate.format('MMMM YYYY') }}</span>
          <span v-else-if="view.id === 'week'">WEEK {{ view.startDate.getWeek() }} ({{ view.startDate.format('MMM YYYY') }} )</span>
          <span v-else-if="view.id === 'day'">{{ view.startDate.format('dddd D MMMM (YYYY)') }}</span>
          
        </template>

        <template  #event="{event, view}">
          <div v-if="view === 'week'" class="week_view">
            <v-icon>{{ event.icon }}</v-icon>
            <span class=""> {{event.status}}</span>
            <div class="vuecal__event-title" v-html="event.title" />
            <span class=""> {{event._id}}</span>
            <small class="vuecal__event-time">
              <!-- Using Vue Cal Date prototypes (activated by default) -->
              <table class="table-m">
                <thead>
                  <tr>
                    <th>EAN</th>
                    <th>{{ event.type === 'pallet' ? "Pallets" : "Dozen" }}</th>
                    <th title="Verwacht/geleverd">V/G</th>
                  </tr>
                </thead>
                <tr v-for="item in event.items" v-bind:key="item._id">
                  <td>{{ item.product_id?.ean }}</td>
                  <td>{{ item.units }} x {{ item.packed_per }} pcs</td>
                  <td>{{ item.pieces_expected }}/ {{ item.pieces_checked_in }}</td>
                </tr>
              </table>
            </small>
            <p>{{event.track_trace}}</p>
          </div>
          <div v-else>
            {{ event.title }}
          </div>
        </template>

        <!-- Custom cells -->
        <template #cell-content="{  view, events,  }">
         
          <span class="vuecal__no-event" v-if="['week', 'day'].includes(view.id) && !events.length">Nothing here 👌</span>
        </template>

      </vue-cal>
    </div>
  </template>
  
  <script>
  
  import VueCal from 'vue-cal'
  import 'vue-cal/dist/vuecal.css'


  import { mapGetters, mapActions } from 'vuex';
  
  export default {
    computed: {
      ...mapGetters(['calendarEvents'])
    },
    methods: {
        ...mapActions(['fetchPendingItemsReport']) 
    },
    components: {
      VueCal
    },
    created() {
      this.fetchPendingItemsReport();
    }
  }
  </script>

  <style  lang="scss">
    .vuecal {
      &__title {
        background: var(--color-primary);
      }
      &__event {
        @include box-bg-solid;
        margin:1rem;
        width: calc(100% - 2rem);
        text-align: left;
        padding: 1rem;
      }
    }

    .table-m {
      width: 100%;
      border-collapse: collapse;
      margin-top: 1rem;
      th, td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
        font-size: 1rem;
      }
      th {
        background-color: var(--color-primary);
        color: white;
      }
    }
  </style>