<template>
  <div>
    <div>
      <div class="state-text">
        <h3>{{ this.trx.orders.product_selector_text }}</h3>
      </div>
      <ProductSearch />
      <div>
        <div class="infoheader">
          <h4>{{ this.trx.products.Product }}</h4>
          <h4>{{ this.trx.orders.Stock }}</h4>
        </div>
        <ProductList />
      </div>
    </div>
    <div class="container">
      <SelectedProductList :updateStep="updateStep" />
    </div>
  </div>
</template>
<script>
import trx from "@/translations";

import ProductSearch from "../components/product_search.vue";
import ProductList from "../components/product_list.vue";
import SelectedProductList from "../components/selected_product_list.vue"

export default {
  name: "ProductSelecter",
  data() {
    return {
      trx,
    }
  },
  props: {
    updateStep: Function,
  },
  components: {
    ProductSearch,
    ProductList,
    SelectedProductList,
  },
};
</script>

<style lang="scss" scoped>
.infoheader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.product-list {
  margin-bottom: 20rem;
}
</style>
