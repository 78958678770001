<template>
  <div class="list-container">
    <div
      v-bind:class="
        selected_products_open
          ? 'slider-open container'
          : 'slider-closed container'
      "
    >
      <div class="slider-container">
        <div class="slider-items">
          <h3>{{ this.trx.orders.products_in_order }}: {{ order_products.length }}</h3>
          <div
            class="slider-button"
            v-on:click="selected_products_open = !selected_products_open"
          >
            <svg
              stroke="green"
              width="25"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.93129 14.43L12.0013 20.5L18.0713 14.43"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.0015 3.50008L12.0015 20.3301"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="order-products" v-if="this.selected_products_open">
        <OrderProductItem
          v-for="(product, idx) in this.order_products"
          :key="'order_product_' + product._id"
          :item="product"
          :lastItem="idx === order_products.length - 1"
        />
      </div>
      <div class="order-controls" v-if="this.selected_products_open && this.order_products.length > 0" v-on:click="toSending()">
        <div class="order-controls-items">
          <h2 class="send-order-text">{{ this.trx.orders.to_sending }}</h2>
          <button class="order-button" v-on:click="toSending">
            <svg
              stroke="green"
              width="24"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.4316 5.92993L20.5016 11.9999L14.4316 18.0699"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.50146 12H20.3315"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import trx from "@/translations";

import { mapGetters } from "vuex";
import OrderProductItem from "./order_product_item.vue";

export default {
  name: "SelectedProductList",
  data() {
    return {
      trx,
      selected_products_open: false,
    }
  },
  props: {
    updateStep: Function,
  },
  methods: {
    toSending() {
      this.updateStep(2);
    },
  },
  computed: {
    ...mapGetters(["order_products"]),
  },
  components: {
    OrderProductItem,
  },
};
</script>
<style lang="scss" scoped>
.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.slider {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  background-color: var(--color-primary);
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  z-index: 10000;

  &-closed {
    @extend .slider;
    min-height: 14rem;
    padding: 2rem 2rem 8rem 2rem;

    .slider-button {
      transform: rotate(180deg);
    }
  }

  &-open {
    @extend .slider;
    height: 80vh;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  @media screen and (min-width: $bp-medium) {
    left: 53%;
  }

  @media screen and (max-width: $bp-largest) {
    max-width: 900px;
  }
}

.slider-icon {
  width: 30%;
  height: 0.77rem;
  border-radius: 2rem;
  background-color: var(--color-white);
}

.slider-items {
  justify-self: start;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    color: white;
  }
}

.slider-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 0.5rem;
  background-color: white;
  cursor: pointer;
}

.send-order-text {
  @media screen and (min-width: $bp-medium) {
    font-size: medium;
  }
}

.order-products {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 1rem;
  padding-bottom: 5rem;
}

.order-controls {
  background-color: var(--color-primary);
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2rem 2rem 8rem 2rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  color: white;

  h2 {
    color: white;
  }

  &-items {
    display: flex;
    gap: 2rem;
    align-items: stretch;
  }
}


.order-button {
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding: 0.77rem;
  background-color: white;
  cursor: pointer;
}
</style>
